import React, { useEffect, useState } from "react";
import MapPage from "../MapPage/MapPage";

function GrowMaster() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = () => {
    const data = [
      {
        id: "1",
        type: "Grow Master",
        name: "John Doe",
        location: "California",
        experience: "10 years",
        crops: ["Tomatoes", "Lettuce", "Carrots"]
      },
      {
        id: "2",
        type: "Grow Master",
        name: "Jane Smith",
        location: "Texas",
        experience: "5 years",
        crops: ["Corn", "Peppers", "Spinach"]
      },
    ];
    setUsers(data);
  };

  return (
    <div>
      <MapPage users={users} />
    </div>
  );
}

export default GrowMaster;
