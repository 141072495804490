import React from 'react'

const ProjectHero = () => {

    return (

        <div className='container py-5'>
            <div className="text-center">
                <h1 className="heading fw-600">My Projects</h1>
                <p>
                    Choose the plan that’s tailored to your needs and start experiencing
                    the full benefits today. Whether you're just getting started or
                    looking to upgrade, we have options designed for every level. Select a
                    plan to unlock exclusive features and take your experience to the next
                    level!
                </p>
            </div>
        </div>
    )
}

export default ProjectHero;
