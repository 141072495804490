import React from "react";
import "./TermsConditions.css";
export default function TermsConditionsPage() {
  return (
    <div className="terms-conditions-page">
      <div className="hero-section">
        <div className="container">
          <div className="section-heading">
            <h1>Terms & Conditions</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="content pb-md-3 pt-md-3 py-2 mb-4">
          <div className="content-text">
            <p>
              The terms and conditions below are of application to all those who
              sign up as beta testers of our Software Applications. If you are
              going to participate in our beta testing programming, please read
              the terms below carefully. If the meaning or intention of a clause
              in the contract is unclear, please get in touch with us for
              clarification. By participating in our programming you are
              accepting the terms below.
            </p>
            <p>
              These terms are not applicable to any final, non-beta versions of
              any mobile application or software product released by GrowX.
            </p>
          </div>
          <div className="content-heading">
            BETA TESTING PROGRAMME TERMS AND CONDITIONS
          </div>
          <div className="content-text">
            <p>
              THIS AGREEMENT is made by and between GrowX A.I. Technologies Inc.
              (“we”, “GrowX” or the “Company”), and You (“you”, “Licensee” or
              the “User”).
            </p>
          </div>
          <div className="content-heading">GENERAL TERMS AND CONDITIONS</div>
          <div className="content-text">
            <ol>
              <li>
                GrowX has developed a software application, including
                modifications, enhancements, improvements, updates, additions,
                derivative works, documentation and related material
                (“Software”).
              </li>
              <li>
                GrowX desires that the Software be tested prior to general
                release.
              </li>
              <li>
                Licensee wishes to serve as a Beta tester for such Software;
              </li>
            </ol>
            <p>
              NOW, THEREFORE, in consideration of the mutual covenants and
              premises herein contained, the parties hereto agree as follows:
            </p>
            <ol>
              <li>
                GrowX reserves the right to modify or terminate the Software or
                your access to the Software or the participation in our beta
                testers programming for any reason, without notice, at any time,
                and without liability to you.
              </li>
              <li>
                GrowX grants to Licensee a non-exclusive, non-transferable
                license to use the Software on a single computer at Licensee’s
                business location solely for Beta testing and Beta use from
                effective date of agreement to 45 days after official release
                date of the products(s), subject to the term and conditions
                below.
              </li>
              <li>
                In consideration for receiving a copy of the Software for
                testing, Licensee agrees to serve as a “Beta Tester” for the
                Software and will notify GrowX of all problems and ideas for
                enhancements which come to Licensee’s attention during the
                period of this Agreement, and hereby assigns to GrowX all right,
                title and interest to such enhancements and all property rights
                therein including without limitation all patent, copyright,
                trade secret, mask work, trademark, moral right or other
                intellectual property rights.
              </li>
              <li>
                Licensee agrees that Software is the sole property of GrowX and
                includes valuable trade secrets of GrowX. Licensee agrees to
                treat Software as confidential and will not without the express
                written authorization of GrowX:
                <ul>
                  <li>
                    Demonstrate, copy, sell or market Software to any third
                    party; or
                  </li>
                  <li>
                    Publish or otherwise disclose information relating to
                    performance or quality of the Software to any third party;
                    or
                  </li>
                  <li>
                    Modify, reuse, disassemble, decompile, reverse engineer or
                    otherwise translate Software or any portion thereof.
                  </li>
                </ul>
              </li>
              <li>
                Software is prerelease code and is not at the level of
                performance or compatibility of a final, generally available
                product offering. Software may not operate correctly and may be
                substantially modified prior to first commercial shipment, or
                withdrawn. Software is provided “AS IS” without warranty of any
                kind. The entire risk arising out of the use or performance of
                Software remains with Licensee. In no event shall GrowX be
                liable for any damage whatsoever arising out of the use of or
                inability to use Software, even if GrowX has been advised of the
                possibility of such damages.
              </li>
              <li>
                The Licensee upon completion of the Beta test agrees to provide
                material, statistics, or information that is not deemed
                confidential to Licensee for use in press releases, customer
                testimonials, and as a reference in marketing and sales
                initiatives by GrowX. Licensee will provide a quote to GrowX
                that may be used in a press release.
              </li>
              <li>
                This License Agreement shall be governed, construed and enforced
                in accordance with the laws of the United States. Any notice
                required by this Agreement shall be given by prepaid, first
                class, certified mail, return receipt requested to above address
                or such other address as may be given from time to time under
                the terms of this notice provision.
              </li>
              <li>
                This Agreement constitutes the entire and only agreement between
                the parties for Software and all other prior negotiations,
                representations, agreements, and understandings are superseded
                hereby. No agreements altering or supplementing the terms hereof
                may be made except by means of a written document signed by the
                duly authorized representatives of the parties.
              </li>
              <li>
                Licensee shall comply with all applicable federal, state and
                local laws, regulations, and ordinances in connection with its
                activities pursuant to this Agreement.
              </li>
              <li>
                Failure of GrowX to enforce a right under this Agreement shall
                not act as a waiver of that right or the ability to later assert
                that right relative to the particular situation involved.
              </li>
              <li>
                If any provision of this Agreement shall be found by a court to
                be void, invalid or unenforceable, the same shall be reformed to
                comply with applicable law or stricken if not so conformable, so
                as not to affect the validity or enforceability of this
                Agreement
              </li>
              <li>
                The information you send us will be deemed confidential, except
                as specified in clause 6. GrowX will make reasonable efforts to
                keep such information private, and will not sell it or disclose
                it to third parties, except with service providers as described
                in the privacy policy of our website, or when required to do so
                by law.
              </li>
              <li>
                GrowX reserves the right to update these terms and conditions
                without prior notice. You agree that we may notify you of the
                Updated Terms by posting them on our website, and that your use
                of the Software after the effective date of the Updated Terms
                constitutes your agreement to the Updated Terms. Therefore, you
                should review these Terms of Use and any Updated Terms before
                using the Software. The Updated Terms will be effective as of
                the time of posting, or such later date as may be specified in
                the Updated Terms, and will apply to your use of the Software
                from that point forward. These Terms of Use will govern any
                disputes arising before the effective date of the Updated Terms.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
