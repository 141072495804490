import React from "react";

export default function Plan(props) {
  const { plan, onPlanClick } = props;

  return (
    <div>
      <div className={plan && plan.isActive ? "active plan currentActivePlan position-relative" : "plan"}>
        <div className="active-tag py-2 px-5 position-absolute">
            <p>Current Plan</p>
        </div>
        <div>
          <div className="plan-name">
            <svg
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="72" height="72" rx="16" fill="#ECEBFF" />
              <path
                d="M57.1634 46.6464L57.1727 46.6413H57.1546L46.5283 40.8833L35.8844 35.1152L25.24 40.8833L14.6138 46.6413H14.5957L14.605 46.6464L14.5957 46.6515H14.6138L25.24 52.4094L35.8844 58.1775L46.5283 52.4094L57.1546 46.6515H57.1727L57.1634 46.6464Z"
                fill="#DCD8FF"
              />
              <path
                d="M57.1634 24.5312L57.1727 24.5265H57.1546L46.5283 18.7681L35.8844 13L25.24 18.7681L14.6138 24.5265H14.5957L14.605 24.5312L14.5957 24.5362H14.6138L25.24 30.2946L35.8844 36.0627L46.5283 30.2946L57.1546 24.5362H57.1727L57.1634 24.5312Z"
                fill="#25ADB1"
              />
              <path
                d="M14.6133 24.0625V47.1151L35.8839 35.589L14.6133 24.0625Z"
                fill="#B8B1FF"
              />
              <path
                d="M57.155 24.0625V47.1151L35.8848 35.589L57.155 24.0625Z"
                fill="#B8B1FF"
              />
            </svg>
            <div className="info">
              <div className="serve">{plan.name}</div>
              <div className="name">{plan.type}</div>
            </div>
          </div>
          <div className="plan-desc pt-3">{plan.desc}</div>
          <div className="whats-included mt-2">
            <div className="head">What’s included</div>
            {plan.whatsIncluded.map((included, index) => {
              return (
                <div className="included" key={index}>
                  {included.value ? (
                    <img
                      src={
                        plan.isActive
                          ? "./images/check-mark-white.svg"
                          : "./images/checkmark-circle.svg"
                      }
                    />
                  ) : (
                    <img src="./images/close-circle.svg" />
                  )}
                  <p>{included.label}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="get-start-button">
        <button className="btn" onClick={() => onPlanClick(plan)}>{plan.buttonText}</button>
        </div>
      </div>
    </div>
  );
}
