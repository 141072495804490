import React, { useEffect, useState } from "react";
import Plan from "./Plan";
import "./pricing.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function Pricing() {
  const [isLoading, setLoading] = useState(true);
  const [plansData, setPlansData] = useState([]);
  const [plansDataApi, setPlansDataApi] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [approvalUrl, setApprovalUrl] = useState("");
  const [isIframeLoading, setIframeLoading] = useState(true);
  const [paypalScriptOptions] = useState({
    "client-id": "AX_tElfvO1wIZu2vpLLjyGsHo_xau0qCU3hFGgdRz50yWTdD_vlhak9aIjZsxJNeM9sQjwCKitCTiKlg", 
    currency: "USD",
    intent: "subscription",
    vault: true,
  });


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/growx/subscriptions`)
      .then((response) => {
        setPlansDataApi(response.data);
      })
      .catch((error) => {
        console.error("Error fetching plans:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const defaultPlansData = [
      {
        id: 1,
        name: "Basic",
        type: "Free (Lifetime)",
        price: 0,
        duration: "",
        currency: "USD",
        desc: "",
        buttonText: "Get Started",
        isActive: true,
        whatsIncluded: [
          { label: "All Videos", value: true },
          { label: "Grow Log", value: true },
          { label: "Technician On Demand", value: false },
          { label: "Send Emails", value: false },
          { label: "Live Chat", value: false },
          { label: "Video Consultancy (4 Calls)", value: false },
        ],
      },
      {
        id: 2,
        name: "Standard",
        type: "$199/Year",
        price: 199,
        duration: "Year",
        currency: "USD",
        desc: "",
        buttonText: "Buy Now",
        isActive: false,
        whatsIncluded: [
          { label: "All Videos", value: true },
          { label: "Grow Log", value: true },
          { label: "Technician On Demand", value: true },
          { label: "Send Emails", value: false },
          { label: "Live Chat", value: false },
          { label: "Video Consultancy (4 Calls)", value: false },
        ],
      },
      {
        id: 3,
        name: "Premium",
        type: "$299/Year",
        price: 299,
        duration: "Year",
        currency: "USD",
        desc: "",
        buttonText: "Buy Now",
        isActive: false,
        whatsIncluded: [
          { label: "All Videos", value: true },
          { label: "Grow Log", value: true },
          { label: "Technician On Demand", value: true },
          { label: "Send Emails", value: true },
          { label: "Live Chat", value: true },
          { label: "Video Consultancy (4 Calls)", value: true },
        ],
      },
    ];

    // Get user data from local storage
    const growerUser = JSON.parse(localStorage.getItem("grower_user"));
    const currentSubscriptionId = growerUser?.user?.current_subscription?.id;

    // Set the plan to active based on user's current subscription
    const updatedPlansData = defaultPlansData.map((plan) => ({
      ...plan,
      isActive:
        plan.id === currentSubscriptionId ||
        (plan.id === 1 && !currentSubscriptionId),
    }));

    setPlansData(updatedPlansData);
    setLoading(false);
  }, []);


  const handlePlanClick = (plan) => {
    const growerUser = JSON.parse(localStorage.getItem("grower_user"));
    const email = growerUser?.user?.email;
    const token = growerUser?.token;

    if (!growerUser) {
      if (plan.id === 1 && plan.isActive) {
        toast.info("You are using the Basic plan already.");
        return;
      } else {
        toast.error("Not authorized. Please log in first.");
        return;
      }
    }

    if (!token) {
      toast.error("Not authorized. Please log in first.");
      return;
    }
    if (!email) {
      toast.error("Email not found. Please log in again.");
      return;
    }

    if (plan.id === 1 && plan.isActive) {
      toast.info("Basic plan already subscribed");
    } else if ([1, 2, 3].includes(plan.id)) {
      const payload = {
        subscription_id: plan.id,
        app: "GROWX",
        email: email,
      };

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/growx/buy-subscription`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (
            response.data.status === "PENDING_APPROVAL" &&
            response.data.approval_url
          ) {
            const approvalUrl = response.data.approval_url;
            console.log(approvalUrl, "approve url paypal");
            setApprovalUrl(approvalUrl);
            toast.info(
              "Redirecting to PayPal for approval. Please follow the link."
            );
          } else {
            toast.success("Subscription purchased successfully!");
          }
        })
        .catch((error) => {
          console.error("Error object:", error);
          if (error.response) {
            console.log("Error Response Data:", error.response.data);
            console.log("Error Response Headers:", error.response.headers);
            console.log("Error Response Status:", error.response.status);
          }
          // Check for 302 status code
          if (error.response?.status === 302) {
            const approvalUrl = error.response.data.approval_url;
            console.log(approvalUrl, "redirect URL from API");
            setApprovalUrl(approvalUrl);
            toast.info("Redirecting to PayPal for approval...");
          } else {
            const errorMessage =
              error.response?.data?.message || "Error purchasing subscription.";
            console.error("Error purchasing subscription:", error);
            toast.error(errorMessage);
          }
        });
    }
  };

  return (
    <div className="pricing-page container">
      <ToastContainer />

      <div className="text-center">
        <h1 className="heading">Our pricing plans</h1>
        <p>
          Choose the plan that’s tailored to your needs and start experiencing
          the full benefits today. Whether you're just getting started or
          looking to upgrade, we have options designed for every level. Select a
          plan to unlock exclusive features and take your experience to the next
          level!
        </p>
      </div>
      {isLoading ? (
        <div className="text-center my-5 py-5 text-dark">loading...</div>
      ) : (
        <div className="plans mb-5 mt-5">
          {plansData && plansData.length ? (
            <div className="row">
              {plansData.map((plan, index) => (
                <div className="col-md-6 col-lg-4" key={index}>
                  <Plan plan={plan} onPlanClick={handlePlanClick} />
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      )}

      {showModal && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          tabIndex=""
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-between">
                <h5 className="modal-title" id="exampleModalLabel">
                  PayPal Approval
                </h5>
                <button
                  type="button"
                  className="close px-2 bg-transparent border-0"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                >
                  <span className="fs-1" aria-hidden="true">
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <p>Please follow the link for approval:</p>
                {approvalUrl ? (
                  <iframe
                    src={approvalUrl}
                    width="100%"
                    height="500px"
                    allow="payment"
                    frameBorder="0"
                    title="PayPal Approval"
                    sandbox="allow-forms allow-same-origin allow-scripts allow-popups allow-top-navigation-by-user-activation"
                    onLoad={() => setIframeLoading(false)}
                    onError={() => {
                      setIframeLoading(false);
                      toast.error("Failed to load PayPal form.");
                    }}
                  ></iframe>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Pricing;
