import React from 'react'
import ProjectHero from '../../components/projectsComponents/projectHero';
import ProjectListing from '../../components/projectsComponents/projectListing';

const Projects = () => {

  return (

    <>
      <ProjectHero />
      <ProjectListing />
    </>

  )
}

export default Projects;
