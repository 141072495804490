import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NotificationsContext = createContext();

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState([]);

    const isLoggedIn = () => {
        const growerUser = JSON.parse(localStorage.getItem("grower_user"));
        return growerUser && growerUser.token;
    };

    // Fetch notifications from the server
    const fetchNotifications = async () => {
        try {
            if (!isLoggedIn()) return;
            const growerUser = JSON.parse(localStorage.getItem("grower_user"));
            const token = growerUser?.token;
            const endpoint = `${process.env.REACT_APP_BASE_URL}/api/growx/notifications`;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            const response = await axios.get(endpoint, config);
            const fetchedNotifications = response.data.data;

            setNotifications(fetchedNotifications);
            setUnreadNotifications(fetchedNotifications.filter((n) => n.status === "0"));
        } catch (err) {
            console.error("Error fetching notifications:", err);
        }
    };

    // Mark a notification as read
    const markNotificationAsRead = async (notificationId) => {
        try {
            if (!isLoggedIn()) return;
            const growerUser = JSON.parse(localStorage.getItem("grower_user"));
            const token = growerUser?.token;
            const endpoint = `${process.env.REACT_APP_BASE_URL}/api/growx/notification/update-status/${notificationId}`;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            const response = await axios.put(endpoint, {}, config);

            if (response.status === 200 && response.data.status) {
                // Handle different messages from the API response
                if (response.data.message === "Notification Already Seen") {
                    toast.info("Notification already marked as read");
                } else {
                    toast.success("Notification marked as read");

                    // Update local state after marking as read
                    const updatedNotifications = notifications.map((notification) =>
                        notification.id === notificationId
                            ? { ...notification, status: "1" }
                            : notification
                    );
                    setNotifications(updatedNotifications);
                    setUnreadNotifications(updatedNotifications.filter((n) => n.status === "0"));
                }
            } else {
                toast.error("Failed to mark notification as read");
            }
        } catch (err) {
            toast.error("An error occurred while updating the notification");
            console.error("Error marking notification as read:", err);
        }
    };
    useEffect(() => {
        fetchNotifications();
    }, []);


    const deleteNotification = async (notificationId) => {
        try {
            if (!isLoggedIn()) return;
            const growerUser = JSON.parse(localStorage.getItem("grower_user"));
            const token = growerUser?.token;
            const endpoint = `${process.env.REACT_APP_BASE_URL}/api/growx/notifications`;

            const payload = {
                id: [notificationId],
                app: "GROWX",
            };

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios.delete(endpoint, {
                data: payload,
                ...config,
            });

            if (response.status === 200 && response.data.status) {
                toast.success("Notification deleted successfully");
                const updatedNotifications = notifications.filter(
                    (notification) => notification.id !== notificationId
                );
                setNotifications(updatedNotifications);
                setUnreadNotifications(updatedNotifications.filter((n) => n.status === "0"));
            } else {
                toast.error(response.data.message || "Failed to delete notification");
            }
        } catch (err) {
            toast.error("An error occurred while deleting the notification");
            console.error("Error deleting notification:", err);
        }
    };


    // Automatically fetch notifications every minute
    useEffect(() => {
        fetchNotifications();
        const intervalId = setInterval(fetchNotifications, 60000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
                      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeButton rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <NotificationsContext.Provider
                value={{
                    notifications,
                    unreadNotifications,
                    fetchNotifications,
                    markNotificationAsRead,
                    deleteNotification,
                }}
            >

                {children}
            </NotificationsContext.Provider>
        </>
    );
};
