import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PayPalSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const subscriptionId = urlParams.get("subscription_id");
    const baToken = urlParams.get("ba_token");

    if (subscriptionId && baToken) {
      localStorage.setItem(
        "subscriptionData",
        JSON.stringify({ subscriptionId, baToken })
      );
      toast.success("Payment successful! Subscription data stored.");
      navigate("/pricing"); // Redirect to pricing or another page
    } else {
      toast.error("Payment failed or was canceled.");
      navigate("/pricing");
    }
  }, [navigate]);

  return <div>Processing PayPal Payment...</div>;
};

export default PayPalSuccess;
