import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import "../project.css"
import Loader from '../../loader';
import "../../../assets/style/index.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProjectListing = () => {
    const [projects, setProjects] = useState([]);
    const [error, setError] = useState(null);
    console.log(projects, "projects ");
    const [selectedProject, setSelectedProject] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const projectsPerPage = 6;
    const [isGrowerUser, setIsGrowerUser] = useState(false);


    // Check for grower_user in localStorage
    const checkGrowerUser = () => {
        const growerUser = JSON.parse(localStorage.getItem("grower_user"));
        setIsGrowerUser(!!growerUser);
    };

    useEffect(() => {
        checkGrowerUser();

        const interval = setInterval(() => {
            checkGrowerUser();
        }, 1000); // Check every second for real-time updates

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);


    useEffect(() => {
        const fetchProjects = async () => {
            const growerUser = JSON.parse(localStorage.getItem("grower_user"));
            const token = growerUser?.token;
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/growx/projects`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setProjects(response.data.data);
            } catch (err) {
                console.error('Error fetching projects:', err);
                setError('Failed to fetch projects');
            }
        };
        fetchProjects();
    }, []);

    const handleExport = async () => {
        const growerUser = JSON.parse(localStorage.getItem("grower_user"));
        const token = growerUser?.token;

        if (!selectedProject || !selectedProject.id) {
            toast.error("No project selected for export.");
            return;
        }

        const payload = {
            ids: [selectedProject.id.toString()],
        };

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/growx/export-project-data`,
                {
                    params: payload,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                toast.success("Request Recieved for your data export. You will be notified once your download is ready as it takes a few minutes to prepare.");
                setShowModal(false);
            } else {
                toast.error("Failed to export project data.");
            }
        } catch (err) {
            console.error("Error exporting project data:", err);
            toast.error("An error occurred while exporting the project data.");
        }
    };
    ;


    const handleShowDetails = (project) => {
        setSelectedProject(project);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedProject(null);
        setShowModal(false);
    };

    const handleSeeMore = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const displayedProjects = projects.slice(0, currentPage * projectsPerPage);
    if (!isGrowerUser) {
        return <p className="text-center mb-5 fs-4">Please log in to view projects.</p>;
    }
    return (
        <>
            <ToastContainer />

            <div className='container mb-5 pb-5 position-relative'>
                <div className='projects-cards pb-5'>
                    {displayedProjects.length > 0 ? (
                        displayedProjects.map((project, index) => (
                            <div key={project.id} className='porject-card p-3 position-relative'>
                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <p className='plant-data'>{project.project_title}</p>
                                    <p className='project-date'>{project.created_at}</p>
                                </div>
                                <div class="hover-card-box position-absolute w-100 h-100 top-0 bottom-0 d-flex justify-content-center align-items-center gap-3">
                                    <button onClick={() => handleShowDetails(project)} class="bg-transparent show-details-btn">
                                        Show Details
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        !error && <div className='d-flex justify-content-center align-items-center w-100 mx-auto position-absolute '><Loader /></div>
                    )}
                </div>

                {projects.length > displayedProjects.length && (
                    <div className="text-center">
                        <button onClick={handleSeeMore} className="btn btn-primary px-4">See More</button>
                    </div>
                )}

                {/* Modal to show project details */}
                <Modal show={showModal} onHide={handleCloseModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedProject?.project_title || 'Project Details'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedProject ? (
                            Array.isArray(selectedProject.project_daily_data) && selectedProject.project_daily_data.length > 0 ? (

                                <>
                                    <Accordion defaultActiveKey="0">
                                        {selectedProject.project_daily_data.map((dailyData, index) => (
                                            <Accordion.Item eventKey={index.toString()} key={index}>
                                                <Accordion.Header className='project-heading'>
                                                    Plant Data {dailyData.created_at || 'N/A'}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    {dailyData.image_path && (
                                                        <img className='plant-image-data rounded-3 my-4 w-100'
                                                            src={dailyData.image_path}
                                                            alt={`Daily Data ${index + 1}`}

                                                        />
                                                    )}
                                                    {dailyData.daily_data && (
                                                        <div className='plant-data-sec accordian-table-data'>
                                                            <div className="table-responsive">
                                                                <table class="table text-white mt-2">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" className="bg-transparent border-0 greenish-text environ w-100">Environment</th>
                                                                            <th scope="col" className="bg-transparent border-0 greenish-text fs-6 fw-500 text-center">High</th>
                                                                            <th scope="col" className="bg-transparent border-0 greenish-text fs-6 fw-500 text-center">Low</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th className="bg-transparent border-0 text-black fs-6 fw-500">Temperature</th>
                                                                            {dailyData.daily_data.enviroment && (
                                                                                <td className="bg-transparent border-0 text-black">
                                                                                    <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                        {dailyData.daily_data.enviroment.temperature.high || "N/A"}
                                                                                    </p>
                                                                                </td>
                                                                            )}
                                                                            <td className="bg-transparent border-0 text-black">
                                                                                <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                    {dailyData.daily_data.enviroment.temperature.low || "N/A"}
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className="bg-transparent border-0 text-black fs-6 fw-500">Humidity %</th>
                                                                            <td className="bg-transparent border-0 text-black">
                                                                                <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                    {dailyData.daily_data.enviroment.humidity.high || "N/A"}
                                                                                </p>
                                                                            </td>
                                                                            <td className="bg-transparent border-0 text-black">
                                                                                <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                    {dailyData.daily_data.enviroment.humidity.low || "N/A"}
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className="bg-transparent border-0 text-black fs-6 fw-500">CO2 PPM</th>
                                                                            <td className="bg-transparent border-0 text-black">
                                                                                <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                    {dailyData.daily_data.enviroment.co2.high || "N/A"}
                                                                                </p>
                                                                            </td>
                                                                            <td className="bg-transparent border-0 text-black">
                                                                                <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                    {dailyData.daily_data.enviroment.co2.low || "N/A"}

                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="table-responsive">
                                                                <table class="table text-white mt-2">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" className="bg-transparent border-0 greenish-text fs-5 w-100">Feeding</th>
                                                                            <th scope="col" className="bg-transparent border-0 greenish-text fs-6 fw-500 text-center">High</th>
                                                                            <th scope="col" className="bg-transparent border-0 greenish-text fs-6 fw-500 text-center">Low</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th className="bg-transparent border-0 text-black fs-6 fw-500">Water Volume</th>
                                                                            <td className="bg-transparent border-0 text-black">
                                                                                <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                    {dailyData.daily_data.feeding.water_volume.high || "N/A"}
                                                                                </p>
                                                                            </td>
                                                                            <td className="bg-transparent border-0 text-black">
                                                                                <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                    {dailyData.daily_data.feeding.water_volume.low || "N/A"}

                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className="bg-transparent border-0 text-black fs-6 fw-500">Water pH</th>
                                                                            <td className="bg-transparent border-0 text-black">
                                                                                <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                    {dailyData.daily_data.feeding.water_ph.high || "N/A"}
                                                                                </p>
                                                                            </td>
                                                                            <td className="bg-transparent border-0 text-black">
                                                                                <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                    {dailyData.daily_data.feeding.water_ph.low || "N/A"}

                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className="bg-transparent border-0 text-black fs-6 fs-5 fw-500">Water Temp</th>
                                                                            <td className="bg-transparent border-0 text-black">
                                                                                <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                    {dailyData.daily_data.feeding.water_temperature.high || "N/A"}
                                                                                </p>
                                                                            </td>
                                                                            <td className="bg-transparent border-0 text-black">
                                                                                <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                    {dailyData.daily_data.feeding.water_temperature.low || "N/A"}

                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className='notes'>
                                                                <p className='fs-5 greenish-text fw-600 mb-0'>Notes</p>
                                                                <p> {dailyData.description || "N/A"}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                    <div className='mt-4 d-flex justify-content-center'>
                                        <button onClick={handleExport} className='py-2 px-4 rounded-3 export-btn'>
                                            Export
                                        </button>
                                    </div>
                                </>
                            ) : (

                                <p className='text-center'>No Project Detail Found</p>
                            )
                        ) : (
                            <p>Loading project details...</p>
                        )}

                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default ProjectListing;
