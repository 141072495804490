import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { isAndroid, isIOS } from "react-device-detect";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Testimonials from "../../components/footer/Testimonials";
import GrowMasterSlider from "./GrowMasterSlider";
import "./home.css";

export function CustomIcon() {
  return (
    <svg viewBox="0 0 24 24" width="100%" height="100%" fill="currentColor">
      <path d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path>
    </svg>
  );
}

function Home() {
  const [email, setEmail] = useState("");
  const [isSubscribedLoading, setIsSubscribedLoading] = useState(false);
  const [subscribedEmails, setSubscribedEmails] = useState(new Set());

  const download = () => {
    const appUrl = isAndroid ? process.env.REACT_APP_ANDROID_APP : isIOS ? process.env.REACT_APP_IOS_APP : process.env.REACT_APP_ANDROID_APP;
    window.open(appUrl);
  };

  const openDownloadApp = (url) => {
    if (url) {
      window.open(url);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubscribedLoading(true);

    if (subscribedEmails.has(email)) {
      toast.warning("You are already subscribed!", {
        className: "toaster-box",
        progressClassName: "custom-progress",
        icon: <CustomIcon />,
      });
    } else {
      setSubscribedEmails(new Set(subscribedEmails.add(email)));
      toast.success("Thank you for subscribing!", {
        className: "toaster-box",
        progressClassName: "custom-progress",
        icon: <CustomIcon />,
      });
    }

    setEmail("");
    setIsSubscribedLoading(false);
  };

  return (
    <div className="home-page">
      <ToastContainer position="top-right" />
      <section className="hero" style={{ backgroundImage: `url("./images/home-page-hero.png")` }}>
        <Container>
          <div className="row">
            <div className="col-lg col-md-12 align-self-center">
              <h1>Be You. <br /> Grow. <br /> Without Limits.</h1>
              <div className="app-icon-store-wrapper">
                <div className="row">
                  <div className="col-auto col-6">
                    <img onClick={() => openDownloadApp(process.env.REACT_APP_ANDROID_APP)} src="./images/google-play.svg" alt="google-play" className="img-fluid pointer app-icon-store-img" />
                  </div>
                  <div className="col-auto col-6">
                    <img onClick={() => openDownloadApp(process.env.REACT_APP_IOS_APP)} src="./images/app-store.svg" alt="google-play" className="img-fluid pointer app-icon-store-img" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-auto col-md-12 align-self-center">
              <div className="hero-app-promo-img-wrapper">
                <div className="d-flex justify-content-center">
                  <img src="./images/hero-app-promo-img.svg" alt="GrowXA.I" className="img-fluid" />
                </div>
                <div className="hero-section-links">
                  <ul>
                    <li><Link onClick={download}>Download</Link></li>
                    <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                  </ul>
                </div>
                <div className="powered-by text-white">@2024 Powered by: GrowX A.i Technologies LLC. <br className="d-md-none d-block" /> All Rights Reserved.</div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="video-thumbnail">
        <img src="./images/lets-grow-video-thumbnail.png" className="img-fluid pointer w-100" alt="Let's Grow" />
      </section>
      <section className="trailblazing-features">
        <h2 className="section-heading">Trailblazing Features</h2>
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-4 text-center">
              <img src="./images/grow-log-home-page.png" className="img-fluid" alt="Grow Log Feature" />
            </div>
            <div className="col-md-4 text-center">
              <img src="./images/grow-tracker-home.png" className="img-fluid" alt="Grow Log Feature" />
            </div>
            <div className="col-md-4 text-center">
              <img src="./images/grow-master-home-page.png" className="img-fluid" alt="Grow Log Feature" />
            </div>
          </div>
        </div>
      </section>
      <section className="grow-master">
        <div className="heading-img position-relative">
          <img src="./images/top.svg" className="img-fluid grow-master-section-title" alt="Grow Log Feature" />
          <img src="./images/grow-master-sign-1.svg" className="sign-01" />
          <img src="./images/grow-master-sign-02.svg" className="sign-02" />
        </div>
        <div className="container mt-4 mb-5">
          <div className="row">
            <div className="col-lg-8 col-12 m-auto">
              <div className="slider">
                <GrowMasterSlider />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonials" style={{ backgroundImage: `url("./images/bg-home-page.png")` }}>
        <div className="container">
          <h2 className="section-heading">Testimonials</h2>
          <Testimonials />
        </div>
        <div className="container">
          <div className="download-now-section">
            <div className="row w-100">
              <div className="col-lg-7 align-self-center">
                <h4>Download Now</h4>
                <p>Scan QR to download our App and Start <br /> Growing</p>
                <div className="app-icon-store-wrapper">
                  <div className="row">
                    <div className="col-md-auto col-6">
                      <img onClick={() => openDownloadApp(process.env.REACT_APP_ANDROID_APP)} src="./images/google-play.svg" alt="google-play" className="img-fluid pointer app-icon-store-img" />
                    </div>
                    <div className="col-md-auto col-6 ">
                      <img onClick={() => openDownloadApp(process.env.REACT_APP_IOS_APP)} src="./images/app-store.svg" alt="google-play" className="img-fluid pointer app-icon-store-img" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 align-self-center text-right">
                <img src="./images/download-now-mobile-app-mockup.png" className="download-now-mobile-app-mockup" />
                <img src="./images/scan-here.png" className="scan-here" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="get-exclusive-updates">
            <div className="section-heading small">Get Access To Exclusive Updates</div>
            <div className="row">
              <div className="col-md-6 m-auto position-relative">
                <form onSubmit={handleSubmit}>
                  <div className="get-exclusive-updates-input-wrapper">
                    <input
                      placeholder="your@email.com"
                      className="your-email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <button
                      type="submit"
                      disabled={isSubscribedLoading}
                      className="btn btn-submit-subscription"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
