import Slider from "react-slick";

function Testimonials() {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    speed: 200,
    dots: false,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };
  return (
    <>
      <div className="slider-container">
        <Slider {...settings}>
          <div>
            <div className="testimonial-wrap">
              <img
                src="./images/ed-rosenthal.png"
                className="avatar"
                alt="STEVE"
              />
              <div className="name">Ed Rosenthal</div>
              <div className="designation"></div>
              <div className="text">
                Professional, responsive, and able to keep up with ever-
                changing demand and tight deadlines: That's how I would describe
                Jeramy and his team at Growx Company.
              </div>
              <div className="rating">
                <img src="./images/5-star-rating.png" alt="5 Star" />
              </div>
            </div>
          </div>
          <div>
            <div className="testimonial-wrap">
              <img src="./images/steve.png" className="avatar" alt="STEVE" />
              <div className="name">Steve DeAngelo</div>
              <div className="designation">
                Co-Founder | Last Prisoner Project / Harborside, Inc
              </div>
              <div className="text">
                I love what the team at Growx A.i represents. I'm a big believer
                that what Mother Nature has given us, no man has the right to
                take away. So I'm all for a company that empowers humanity to
                grow and Growx Ai is setting that bar.
              </div>
              <div className="rating">
                <img src="./images/5-star-rating.png" alt="5 Star" />
              </div>
            </div>
          </div>
          <div>
            <div className="testimonial-wrap">
              <img src="./images/chris.png" className="avatar" alt="STEVE" />
              <div className="name">Chris Haufman</div>
              <div className="designation">Co-Founder | StockX, ArtClvb</div>
              <div className="text">
                Professional, responsive, and able to keep up with ever-changing
                demand and tight deadlines: That's how I would describe Jeramy
                and his team at Growx Company.
              </div>
              <div className="rating">
                <img src="./images/5-star-rating.png" alt="5 Star" />
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
}

export default Testimonials;
