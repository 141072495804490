import React from "react";
import Slider from "react-slick";
import "./GrowMasterSlider.css";

function GrowMasterSlider() {
  const settings = {
    className: "center",
    infinite: true,
    slidesToShow: 3,
    speed: 100,
    dots: false,
    arrows: true,
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="slide-img">
          <img src="./images/grow-tracking-mobile.svg" alt="Grow Tracking" />
        </div>
        <div className="slide-img">
          <img src="./images/map-screen-mobile.svg" alt="Map Screen Mobile" />
        </div>
        <div className="slide-img">
          <img src="./images/my-plans-mobile-app.svg" alt="My Plans" />
        </div>
      </Slider>
    </div>
  );
}

export default GrowMasterSlider;
