import React from "react";
import "./LearnMoreContent.css";

function LearnMoreContent(props) {
  const {
    openRegisterModal,
    openTermConditionModal,
    openHgpTermConditionModal,
  } = props;
  return (
    <div className="learn-more">
      <div className="row">
        <div className="col-md-5 col-6 m-auto">
          <div className="powered-by-growx text-center pb-md-4 pb-3">
            <img
              src="/images/powered-by-growx.svg"
              className="img-fluid pointer"
              alt="GrowX"
              onClick={openRegisterModal}
            />
          </div>
        </div>
      </div>
      <h1 className="pb-3">Friends & Family Beta Offer!</h1>
      <div className="content pb-md-3 pt-md-3 py-2 mb-4 text-center">
        <div className="content-heading">HomeGrown Nation,</div>
        <div className="content-text">
          <p>Warm welcome to each of you from the GrowX A.i Team.</p>
          <p>
            We thank you for your consideration in registering for the Beta
            launch of “The GrowX Ai App”.
          </p>
          <p>
            {" "}
            <i>
              “The most prolific grower support technology in the world.”{" "}
            </i>{" "}
          </p>
          <p>
            We’re thrilled to have you take part in this monumental moment in
            our industries history as well as our companies history. To the
            growers around the world who understand that X is the variable of
            the seed you wish to grow. What is your X ? We hope to serve you
            proud on your grow journey with us.
          </p>
          <p>
            As a show of our appreciation and love for all growers around the
            world, and to simply say, Thank You. By registering today you will
            receive our gift to you, an exclusive 1-year paid subscription to
            the trailblazing mobile application “The GrowX A.i App”. Granting
            you complimentary beta access to our GrowLog Feature and services
            for the next 12 months.
          </p>
          <p>
            Additionally, With your registration below, Please enjoy 1 year
            free- HGP membership pricing.
          </p>
          <p>
            This is an Membership Card that will extend to you 15-75% off
            already close to wholesale pricing on over 5,500+ products of your
            most trusted brands. This Gift is being brought forth by our great
            equipment and garden supplies partners at HomeGrown Pros, Inc. Home
            of the MambaLine GrowKit. This membership is normally priced at
            $129.99 a year. Hgp has decided to cover the cost for your first
            year annual membership fee. Simply for registering with us today.
            Now that's love. It’s Grow Time ladies and gentleman. 🌱
          </p>
          <p>
            We hope our gifts to you today only empower your greatest growth.
          </p>
          <p>Please register below and Let's Growwwww !</p>
          <p>TEAM GROWX Ai</p>
          <p className="mb-0">
            #LoveNeverEnds 💚
            <br />
            #FreeThePlant 🌱 #PlantsOverPills 💊 #Free40k 🔒
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-12 text-center mb-3 mb-sm-0 order-1 order-md-0">
          <img
            src="./images/hero-02.svg"
            className="img-fluid pointer learn-more-footer-btn"
            alt="GROWx"
            height={"38px"}
            onClick={openTermConditionModal}
          />
          <div className="terms-conditions-link pt-2">Terms & Conditions</div>
        </div>
        <div className="col-md-4 col-12 text-center mb-3 mb-sm-0">
          <button
            className="theme-btn px-3 learn-more-footer-btn highlighted-btn"
            onClick={openRegisterModal}
          >
            GET REGISTERED
          </button>
        </div>
        <div className="col-md-4 col-12 text-center mb-3 mb-sm-0 order-1">
          <img
            src="./images/hero-04.svg"
            className="img-fluid pointer learn-more-footer-btn"
            alt="HGP"
            height={"38px"}
            onClick={openHgpTermConditionModal}
          />
          <div className="terms-conditions-link pt-2">Terms & Conditions</div>
        </div>
      </div>
    </div>
  );
}

export default LearnMoreContent;
