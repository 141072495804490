import React, { useEffect, useState } from "react";
import MapPage from "../MapPage/MapPage";

function GrowTrack() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = () => {
    const data = [
      {
        id: "1",
        type: "Grow Track",
        name: "Alice Johnson",
        location: "Florida",
        experience: "8 years",
        crops: ["Bananas", "Pineapples", "Strawberries"]
      },
      {
        id: "2",
        type: "Grow Track",
        name: "Bob Williams",
        location: "New York",
        experience: "6 years",
        crops: ["Apples", "Grapes", "Blueberries"]
      },
    ];
    setUsers(data);
  };

  return (
    <div>
      <MapPage users={users} />
    </div>
  );
}

export default GrowTrack;
