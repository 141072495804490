import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import "./about.css";

function About() {
  const [activeSection, setActiveSection] = useState("1");

  const openDownloadApp = (url) => {
    if (url) {
      window.open(url);
    }
  };

  return (
    <div
      className="about-us-page"
      style={{
        backgroundImage: `url("./images/about-us-hero.png")`,
      }}
    >
      <div className="container">
        <div className="about-us-page-wrapper">
          <div className="side-bar">
            <div className="links">
              <div
                className={activeSection == "1" ? "link active" : "link"}
                onClick={() => {
                  setActiveSection("1");
                }}
              >
                Who <br /> we are
              </div>
              <div
                className={activeSection == "2" ? "link active" : "link"}
                onClick={() => {
                  setActiveSection("2");
                }}
              >
                What is <br /> our mission
              </div>
              <div
                className={activeSection == "3" ? "link active" : "link"}
                onClick={() => {
                  setActiveSection("3");
                }}
              >
                our main <br /> features
              </div>
              <div
                className={activeSection == "4" ? "link active" : "link"}
                onClick={() => {
                  setActiveSection("4");
                }}
              >
                what we <br /> do exactly
              </div>
              {/* <div
                className={activeSection == "5" ? "link active" : "link"}
                onClick={() => {
                  setActiveSection("5");
                }}
              >
                meet <br /> our team
              </div> */}
            </div>
          </div>
          <div className="about-us-content">
            {activeSection === "1" ? (
              <div className="section-01 d-flex flex-column">
                <div>
                  <img
                    src="./images/we-are-growx-ai.svg"
                    alt="GrowXAI"
                    className="img-fluid"
                  />
                  <p className="my-3 fs-16">
                    At GROWXAi, we let businesses grow smarter with artificial
                    intelligence. Simply put, our goal is to make AI work for
                    you. Whether it is speeding up some processes or making some
                    decisions easier, we are here to support it all. We built
                    tools and solutions that businesses trust. We are proud of
                    our progress and excited to break more boundaries.
                  </p>
                </div>
                <div className="app-icon-store-wrapper">
                  <img
                    onClick={() => {
                      openDownloadApp(process.env.REACT_APP_ANDROID_APP);
                    }}
                    src="./images/google-play.svg"
                    alt="google-play"
                    className="img-fluid pointer app-icon-store-img"
                  />
                  &nbsp; &nbsp;
                  <img
                    onClick={() => {
                      openDownloadApp(process.env.REACT_APP_IOS_APP);
                    }}
                    src="./images/app-store.svg"
                    alt="google-play"
                    className="img-fluid pointer  app-icon-store-img"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {activeSection === "2" ? (
              <div className="section-02">
                <div className="row">
                  <div className="col">
                    <div className="our-mission">
                      <h2>Our Mission.</h2>
                      <p className="text-ligh fs-16">
                        We're on a mission to make AI practical and helpful for
                        businesses of all sizes. We want companies to succeed
                        without needing to wrestle with complicated tech. By
                        focusing on simplicity and impact, we're creating
                        systems that actually work—boosting growth, saving time,
                        and solving real problems.
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-auto">
                    <div className="text-right">
                      <h1>$800 MM</h1>
                      <h2 className="text-color">In origination</h2>
                    </div>
                    <div className="text-right">
                      <h1>3x Growth</h1>
                      <h2 className="text-color">from 2016-2024</h2>
                    </div>
                  </div> */}
                </div>
              </div>
            ) : (
              ""
            )}
            {activeSection === "3" ? (
              <div className="section-03">
                <div className="row">
                  <div className="col">
                    <div className="our-mission">
                      <h2>Our Main Features</h2>
                      <p className="fs-16">
                        Here's what we bring to the table:
                        <ul className="d-flex flex-column gap-2 mt-3">
                          <li>
                            Smart Insights: We transform messy data into
                            crystal-clear, useful information.
                          </li>
                          <li>
                            We tailor our solutions to each unique business. Our
                            tech is scalable to match your pace, no matter how
                            big or small, or how fast you're growing. Our
                            technology is intuitive-it doesn't have to be hard.
                          </li>
                          <li>
                            Rigorous Security: Your data is safe with us.
                            Period.
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {activeSection === "4" ? (
              <div className="section-04">
                <div className="row">
                  <div className="col-12">
                    <div className="our-mission">
                      <h2>What We Do Exactly</h2>
                      <div className="row mt-md-5 mt-4">
                        <div className="col-auto">
                          <img
                            src="./images/grow-log.png"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg-4 col-md-5 align-self-center">
                          <p className="">
                            GROWLOG makes recording your data easy and
                            stress-free. Be you a project manager or just
                            someone looking to keep things in order, this tool
                            will keep all that you need in one place.
                          </p>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-lg-4 col-md-5 align-self-center">
                          <p className="">
                            GROWMASTER offers you everything you need to take
                            your growth strategy to the next level. Whether for
                            your business or a personal project, it keeps you
                            organized and on point.
                          </p>
                        </div>
                        <div className="col-auto">
                          <img
                            src="./images/grow-master.png"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-auto">
                          <img
                            src="./images/grow-tracking.png"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg-4 col-md-5 align-self-center">
                          <p className="">
                            Ever feel like your goals are slipping out of reach?
                            GROWTRACKER keeps you focused and moving forward.
                            It's designed to help you see what's working and
                            what's not, so you can adjust and stay on track.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {activeSection === "5" ? (
              <div className="section-05">
                <div className="row">
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
