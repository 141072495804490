import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "./AccountDeletion.css";

function AccountDeletion() {
  const [loading, setLoading] = useState(false);

  const {
    getValues,
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    if (
      window.confirm(
        "Are you certain you wish to proceed with the request to delete your account?"
      )
    ) {
      setLoading(true);
      
      // Simulate user authentication and validation
      setTimeout(() => {
        if (data.email === "test@example.com" && data.password === "password123") {
          deleteAccount("mockUserId123");
        } else {
          setLoading(false);
          if (data.password !== "password123") {
            toast("Wrong Password!");
          }
          if (data.email !== "test@example.com") {
            toast("User Not Found!");
          }
        }
      }, 1000);
    }
  };

  const deleteAccount = (id) => {
    if (id) {
      // Simulate account deletion process
      setTimeout(() => {
        alert("Your request has been received and will be reviewed by the admin.");
        setLoading(false);
        reset();
      }, 1000);
    }
  };

  return (
    <div className="auth-wrapper">
      <ToastContainer position="top-right" />
      <div className="login-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-xl-6 m-auto align-self-center ">
              <div className="login-form">
                <div className="login-form-wrapper">
                  <div className="text-center">
                    <img
                      src="/favicon.ico"
                      width={120}
                      className="img-fluid mb-4"
                    />
                  </div>
                  <div className="get-started">
                    <h3>Request Account Deletion</h3>
                  </div>
                  <form className="pt-3 pb-3" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mb-2">
                      <label>Email Address</label>
                      <input
                        type="email"
                        placeholder="Enter your Email here"
                        {...register("email", {
                          required: "Email Address is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Please enter a valid email address",
                          },
                        })}
                        className={
                          errors.email
                            ? "form-control invalid-input"
                            : "form-control"
                        }
                        aria-invalid={errors.email ? "true" : "false"}
                      />
                      {errors.email && (
                        <div className="invalid-feedback">
                          {errors.email.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        placeholder="Enter your Password here"
                        {...register("password", {
                          required: "Password is required",
                        })}
                        className={
                          errors.password
                            ? "form-control invalid-input"
                            : "form-control"
                        }
                        aria-invalid={errors.password ? "true" : "false"}
                      />
                      {errors.password && (
                        <div className="invalid-feedback">
                          {errors.password.message}
                        </div>
                      )}
                    </div>
                    <div className="text-center pt-4">
                      <button
                        className="btn btn-primary submit btn-sm py-2"
                        disabled={loading}
                      >
                        {!loading ? "Submit" : "Submitting..."}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountDeletion;
