import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./RegisterForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";

const RegisterForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const { closeRegisterModal, theme } = props;
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const states = [
    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const payload = {
        email: data.email,
        password: data.password,
        name: `${data.firstName} ${data.lastName}`,
        user_name: data.user_name,
        app: "GROWX",
        role: "GROW_TECH",
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await response.json();
      if (response.ok) {
        setSuccess(true);
        setError("");

        setTimeout(() => {
          navigate("/verify-email", { state: { email: data.email } });
        }, 3000);
        toast.success(
          result.message || "Registration successful! Redirecting..."
        );
      } else {
        if (result.error && result.error.email) {
          toast.error(`Error: ${result.error.email[0]}`);
        } else {
          toast.error(result.message || "Registration failed");
        }
      }
    } catch (err) {
      toast.error("An error occurred while submitting the form");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={theme}>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="register-form">
        <h1>GET REGISTERED</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>First Name:</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("firstName", { required: true })}
                />
                {errors.firstName && (
                  <p className="error text-danger">First Name is required</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Last Name:</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("lastName", { required: true })}
                />
                {errors.lastName && (
                  <p className="error text-danger">Last Name is required</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  className="form-control"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <p className="error text-danger">Email is required</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group position-relative">
                <label>Password:</label>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  {...register("password", { required: true })}
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "65%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "#00898d",
                  }}
                >
                  {showPassword ? (
                    <FaEyeSlash className="fs-5" />
                  ) : (
                    <FaEye className="fs-5" />
                  )}
                </span>
                {errors.password && (
                  <p className="error text-danger">Password is required</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>State:</label>
                <select
                  className="form-control"
                  {...register("state", { required: true })}
                >
                  <option value="">Choose an option</option>
                  {states.map((state, index) => (
                    <option key={index} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <p className="error text-danger">State is required</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Phone Number:</label>
                <input
                  type="tel"
                  className="form-control"
                  {...register("phone", { required: true })}
                />
                {errors.phone && (
                  <p className="error text-danger">Phone number is required</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Are you a grower?</label>
                <select
                  className="form-control"
                  {...register("growerStatus", { required: true })}
                >
                  <option value="">Choose an option</option>
                  <option value="new">I am a new Home Grower</option>
                  <option value="experienced">
                    I am an experienced Home Grower
                  </option>
                  <option value="commercial">I am a Commercial Grower</option>
                  <option value="investor">
                    I am Interested in Investing in (GXAi)
                  </option>
                  <option value="partner">
                    I am a potential Partner Brand
                  </option>
                  <option value="employment">
                    I am looking for Employment
                  </option>
                </select>
                {errors.growerStatus && (
                  <p className="error text-danger">Selection is required</p>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col text-center">
              <button
                type="submit"
                className="btn btn-primary btn-lg rounded font-bold px-4"
                disabled={loading}
              >
                {loading ? "Submitting..." : "SUBMIT"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterForm;
