import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import "./Profile.css";
import GrowTrack from "../../components/GrowTrack/GrowTrack";
import GrowMaster from "../../components/GrowMaster/GrowMaster";
import UserProfilePic from "../../assets/images/userplaceholder.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Profile() {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("account");
  const [successMessage, setSuccessMessage] = useState("");
  const { register, handleSubmit, setValue } = useForm();
  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [currentUser, setCurrentUser] = useState({ image_path: "" });
  const [previewImage, setPreviewImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const growerUser = JSON.parse(localStorage.getItem("grower_user"));
    const token = growerUser?.token;
    console.log(token , "token in profile page ")
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/get-user`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const userData = response.data.data;
        setCurrentUser(userData);
        setUserName(userData.user_name || "");
        setName(userData.name || "");
        setEmail(userData.email || "");
        setPhone(userData.phone || "");
      })

      .catch((error) => {
        console.error("Error fetching plans:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = (data) => {
    const growerUser = JSON.parse(localStorage.getItem("grower_user"));
    const token = growerUser?.token;

    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/growx/update-profile`,
        {
          user_name: userName,
          name,
          email,
          phone,
          app: "GROWX",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSuccessMessage("Profile updated successfully!");
        toast.success(response.data.message || "Profile updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        setSuccessMessage("Failed to update profile.");
        toast.error("Failed to update profile.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Open file input dialog
  const handleUploadClick = () => {
    document.getElementById("fileInput").click();
  };

  // Handle image file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreviewImage(file);
    }
  };

  const handleImageSubmit = () => {
    const growerUser = JSON.parse(localStorage.getItem("grower_user"));
    const token = growerUser?.token;
    if (!previewImage) {
      toast.error("No image selected!");
      return;
    }

    const formData = new FormData();
    formData.append("image", previewImage);

    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/growx/update-image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          toast.success(
            response.data.message || "Profile image updated successfully!"
          );
          setCurrentUser((prevState) => ({
            ...prevState,
            image_path: response.data.user.image_path,
          }));
          setPreviewImage(null);
        } else {
          toast.error("Failed to update profile image.");
        }
      })
      .catch((error) => {
        toast.error("Error updating profile image.");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      if (previewImage) {
        URL.revokeObjectURL(URL.createObjectURL(previewImage));
      }
    };
  }, [previewImage]);
  return (
    <div className="profile-page">
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="container">
        <div className="row">
          <div className="col-xl-12 m-auto col-md-12 col-12">
            <div className="section-heading text-center pb-3 pt-5">
              <div className="user-profile-pic">
                <div className="user-profile-pic">
                  <div className="profile-img mx-auto">
                    <img
                      className="w-100 h-100"
                      src={
                        isLoading
                          ? "loading-placeholder.png"
                          : previewImage
                          ? URL.createObjectURL(previewImage)
                          : currentUser.image_path || UserProfilePic
                      }
                      alt="user profile"
                    />
                  </div>
                  <div className="profile-upadate-btns d-flex gap-3 justify-content-center py-4">
                    {previewImage ? (
                      <div className="text-center">
                        <button
                          className="upload"
                          onClick={handleImageSubmit}
                          disabled={isLoading}
                        >
                          {isLoading ? "Uploading..." : "Update Now"}
                        </button>
                      </div>
                    ) : (
                      <button className="upload" onClick={handleUploadClick}>
                        Upload
                      </button>
                    )}
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>

              <div className="profile-tabs pt-5">
                <div
                  className={
                    currentTab === "account"
                      ? "profile-tab active-tab"
                      : "profile-tab"
                  }
                  onClick={() => setCurrentTab("account")}
                >
                  Account info
                </div>
              </div>
            </div>
            <div className="profile-form-wrapper">
              {currentTab === "account" && (
                <>
                  <form className="pt-3 pb-3" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>User Name</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Elon"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Full name</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Elon Musk"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Email Address</label>
                          <input
                            className="form-control"
                            type="email"
                            placeholder="elon@spacex.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Phone number</label>
                          {/* <input
                            className="form-control"
                            type="text"
                            placeholder="+1 64417 7732"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          /> */}

                          <PhoneInput
                            country={"us"}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                            inputClass="form-control w-100 border-0"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="divider my-5"></div>
                    <div class="linked-accounts">
                      <div class="row mb-3">
                        <div class="col align-self-center">
                          <h3 class="mb-0 fw-600">Subscription Plans</h3>
                          <p class="mb-0">
                            Choose your plan according to your needs
                          </p>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col align-self-center">
                          <p class="mb-0">Current Plan</p>
                          <h3 class="fw-600">
                            Basic<span class="text-color"> Free </span>
                          </h3>
                        </div>
                        <div class="col-auto align-self-center">
                          <div class="accounts mt-0">
                            <div class="google">
                              <Link
                                to="/pricing"
                                class="btn btn-sm btn-primary pe-4 ps-4"
                              >
                                Change
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div class="divider my-3"></div>
                    <div class="row mb-4 linked-accounts">
                      <div class="col align-self-center">
                        <h3 class="mb-0 fw-600">Delete account</h3>
                        <p class="mb-0">
                          By deleting your account you will lose all your data
                        </p>
                      </div>
                      <div class="col-auto align-self-center">
                        <div class="accounts mt-0">
                          <div class="google">
                            <button class="btn btn-sm btn-danger pe-4 ps-4">
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div class="divider my-3"></div>

                    <div className="text-center pt-3">
                      <button
                        type="submit"
                        className="btn btn-primary submit"
                        disabled={loading}
                      >
                        {loading ? "Updating..." : "Save Changes"}
                      </button>
                    </div>
                  </form>
                </>
              )}
              {currentTab === "growTrack" && <GrowTrack />}
              {currentTab === "growMaster" && <GrowMaster />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
