import React from "react";
import "./HgpTermsConditionsContent.css";

function HgpTermsConditionsContent(props) {
  const { openRegisterModal } = props;
  return (
    <div className="terms-conditions">
      <h1 className="pb-3">Terms & Conditions</h1>
      <div className="content pb-md-3 pt-md-3 py-2 mb-4">
        <div className="content-heading">Member Privileges & Conditions</div>
        <div className="content-text">
          <p>
            We look forward to serving you as a HomeGrown Pros, Inc (HGP)
            member. If you have any questions, please ask our member services
            personnel at www.homegrownpros.io membership tab or email us
            directly at Member.Services@TheHomeGrownPros.com. HGP membership is
            subject to our Member Privileges & Conditions, which may change from
            time to time without prior notice.
          </p>
        </div>
        <div className="content-heading">Membership Advantages & Benefits</div>
        <div className="content-text">
          <ul>
            <li>15-75 % off, Closest to wholesale pricing all year round.</li>
            <li>15-75 % off our 5,500+ Plus Products & Services</li>
            <li>
              Free Access to “The GrowX A.i App” (Paid Annual Subscription)
            </li>
            <li>GrowLog Feature Subscription (Paid Annual Subscription)</li>
            <li>VIP Member only Support</li>
            <li>Dropshipping Fastest Track Shipping</li>
            <li>Expanded member privileges</li>
            <li>First In the know: Offer & Announcements</li>
            <li>VIP access to the latest brands, new releases</li>
          </ul>
        </div>
        <div className="content-heading">Membership</div>
        <div className="content-text">
          <ul>
            <li>
              Membership is available to all qualifying individuals 18 years of
              age and over.
            </li>
            <li>
              HomeGrown Pros, Inc (HGP) reserves the right to refuse membership
              to any applicant, and membership may be terminated at HomeGrown
              Pros, Inc discretion and without cause.
            </li>
            <li>
              Membership is subject to any and all rules adopted by HGP,
              including our privacy policies and practices, and they may be
              amended from time to time without notice.
            </li>
            <li>
              Members are required to present a valid photo identification
              issued by the United States, Puerto Rico, Canadian Federal, State,
              or Provincial governments or any valid passport to sign up for
              membership.
            </li>
          </ul>
        </div>
        <div className="content-heading">Membership Cards and Fees</div>
        <div className="content-text">
          <ul>
            <li>
              Membership fee is for one 12-month period from the date of
              enrollment of the Primary Cardholder.
            </li>
            <li>
              Membership renewal must be completed for all cardholders on the
              membership when the renewal is processed.
            </li>
            <li>You will not receive new membership cards each year.</li>
            <li>
              Memberships renewed within 2 months after expiration of the
              current membership year will be extended for 12 months from the
              expiration date. Memberships renewed more than 2 months after such
              expiration will be extended for 12 months from the renewal date.
              All renewals will be assessed at the membership fee in effect on
              the date the membership fee is paid.
            </li>
            <li>
              The Primary Member is responsible for the membership, and must
              authorize cardholder changes, including additions or deletions.
            </li>
            <li>
              The Primary Member must authorize renewal or cardholder changes,
              including additions or deletions, and is responsible for the
              membership.
            </li>
            <li>
              Primary Business Members or Business Administrators may add
              additional Cardholders (Affiliates) to their membership. To add
              Affiliates to your Business Membership, please email us at:
              member.services@thehomegrownpros.com.
            </li>
          </ul>
        </div>
        <div className="content-heading">
          Renewing, Adding or Deleting Cards
        </div>
        <div className="content-text">
          <ul>
            <li>
              Renewal fees are due no later than the last day of the month your
              membership expires. You may remit your renewal fee online at
              www.homegrownpros.io, or directly with your grow pro assigned. HGP
              members may charge their membership fees automatically on any
              Visa® credit/debit card or Mastercard debit card. That card will
              be charged on the first day of your renewal month. If you have not
              signed up for auto renewal, your membership fee will be added to
              your first purchase after expiration.
            </li>
            <li>
              Membership renewal must be completed for all cardholders on the
              membership when the renewal is processed.
            </li>
            <li>You will not receive new membership cards each year.</li>
            <li>
              Memberships renewed within 2 months after expiration of the
              current membership year will be extended for 12 months from the
              expiration date. Memberships renewed more than 2 months after such
              expiration will be extended for 12 months from the renewal date.
              All renewals will be assessed at the membership fee in effect on
              the date the membership fee is paid.
            </li>
            <li>
              The Primary Member is responsible for the membership, and must
              authorize cardholder changes, including additions or deletions.
            </li>
            <li>
              The Primary Member must authorize renewal or cardholder changes,
              including additions or deletions, and is responsible for the
              membership.
            </li>
            <li>
              Primary Business Members or Business Administrators may add
              additional Cardholders (Affiliates) to their membership. To add
              Affiliates to your Business Membership, please email us at:
              member.services@thehomegrownpros.com.
            </li>
          </ul>
        </div>
        <div className="content-heading">
          Risk-Free 100% Satisfaction Guarantee
        </div>
        <div className="content-text">
          <ul>
            <li>
              We are committed to providing quality and value on the products we
              sell with a risk-free 100% satisfaction guarantee on both your
              membership. Limited exceptions apply. See return policy for more
              information.
            </li>
          </ul>
        </div>
        <div className="content-heading">Prices</div>
        <div className="content-text">
          <ul>
            <li>
              Each item is marked with an item number or a UPC Code. The price
              of the item, along with the description and identifying number, is
              posted with the item online with both member and non member
              pricing visible on all said products.
            </li>
          </ul>
        </div>
        <div className="content-heading">Payment</div>
        <div className="content-text">
          <ul>
            <li>
              HomeGrownPros.io accepts: Visa cards, debit/ATM, cash, checks,
              paypal and HGP holiday shop cards.
            </li>
            <li>
              HGP does not accept manufacturers' discount coupons (other than
              those distributed by HGP.)
            </li>
            <li>
              The Primary Member is responsible for purchases made by any
              additional card user.
            </li>
          </ul>
        </div>
        <div className="content-heading">
          Sales and Use Taxes, and Resale Certificates
        </div>
        <div className="content-text">
          <ul>
            <li>
              The member agrees to pay HGP any sales, excise, use or ad tax that
              is imposed on the sale price of the items purchased, which may be
              based on the price before any discounts or promotions. The member
              agrees that they will hold HomeGrown Pros, Inc(HGP) harmless and
              indemnify HGP from any claim, loss, assessment or expense
              occasioned by non-payment of tax to HGP.
            </li>
            <li>
              If any merchandise is being purchased for resale and an exemption
              from tax is sought, the member shall have a valid resale license
              number on file with HGP and shall notify us prior to recording any
              sale online or otherwise. Such declaration, and the products
              purchased thereunder, shall be recorded on a "Certificate for
              Resale." Sales tax will not be charged at the time of purchase
              only on those products the member states are specifically for
              resale; all other products subject to tax will be deemed
              taxable(for business and commercial members only).
            </li>
            <li>
              In the event any product that was purchased for resale (tax free)
              is subsequently consumed or used in any manner other than for
              resale that creates or imposes a sales or use tax, the member
              agrees to report and pay to the proper taxing authority any tax
              due, including penalties and interest.
            </li>
            <li>
              HGP does not deal in any genetics or plant sales. We are an
              equipment and services provider only.
            </li>
          </ul>
        </div>
        <div className="content-heading">General Policies</div>
        <div className="content-text">
          <ul>
            <li>GrowKits are excluded from added member pricing discounts.</li>
            <li>
              It is HGP’s intent to provide a shopping environment free from all
              forms of harassment and discrimination for employees, applicants,
              independent contractors, members and suppliers. We prohibit all
              forms of harassment and discrimination.
            </li>
            <li>
              HGP reserves the right to refuse purchase for any member or non
              member using our sights and platforms.
            </li>
            <li>
              To ensure that all members are correctly charged for the
              merchandise purchased, all receipts and merchandise will be
              inspected before leaving the warehouse.
            </li>
          </ul>
        </div>
        <div className="content-heading text-center">Privacy</div>
        <div className="content-text">
          <p className="text-center">
            We respect your right to privacy. Our Privacy Policy outlines our
            policies and practices in detail. Please see the Privacy Policies
            online at www.homegrownpros.io . © 2024 HomeGrown Pros, Inc (HGP).
            All rights reserved.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col m-auto col-12 text-center mb-3 mb-sm-0">
          <button
            className="theme-btn px-3 fz-20"
            style={{ height: "40px" }}
            onClick={openRegisterModal}
          >
            GET REGISTERED
          </button>
        </div>
      </div>
    </div>
  );
}

export default HgpTermsConditionsContent;
