import React from "react";
import "./PrivacyPolicy.css";
import { Link } from "react-router-dom";
export default function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <div className="hero-section">
        <div className="container">
          <div className="section-heading">
            <h1>Privacy Policy</h1>
            <div>
              <small>
                <i>Last Updated: February 8, 2024</i>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="content pb-md-3 pt-md-3 py-2 mb-4">
          <div className="content-text">
            <p>
              At GrowX A.i Technologies, LLC (GrowX A.i) (GrowX) (GXAI) our
              mission is to improve people’s lives with the world’s most
              prolific grower support technologies. We build and provide
              platforms to help you get from seed to harvest and enjoy your grow
              journey with us. To do that, we need to collect, use, and share
              some of your personal information. This Privacy Policy is meant to
              help you understand how GrowX does that and how to exercise the
              choices and rights you have in your information.
            </p>
            <p>
              GrowX (GXAI) provides additional information about our commitment
              to respecting your /communication service. We collect information
              about these communications and GrowX A.i Platform chat
              communications, including the participants’ phone numbers, the
              date and time, and the contents of SMS and chat messages. For
              security purposes, we may also monitor or record the contents of
              phone calls made through the GrowX A.i Platform, but we will
              always let you know we are about to do so before the call begins.
            </p>
            <p>
              Address Book Contacts. You may set your device permissions to
              grant GrowX A.i access to your contact lists and direct GrowX A.i
              to access your contact list, for example to help you refer friends
              to GrowX A.i. If you choose to do this, we will access the names
              and contact information of the people in your address book.
            </p>
            <p>
              Calendar Information. You may set your device permissions or
              otherwise grant GrowX A.i access to your chosen calendar and
              direct GrowX A.i to access calendar information, for example to
              help you get alerts to order consultations for your upcoming
              service. If you choose to do this, we will access information
              available in your calendar to use in providing you these optional
              features. GrowX A.i will adhere to the applicable calendar
              provider policies, including{" "}
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                target="_blank"
              >
                Google’s API Services User Data Policy
              </a>
              , which impose certain limitations on the use of data collected
              from user’s calendar accounts. You may remove GrowX A.i access to
              your accounts at any time either in-app or through your account
              provider settings.
            </p>
            <p>
              Cookies, Analytics, and Third-Party Technologies. We collect
              information through the use of “cookies”, tracking pixels, data
              analytics tools like{" "}
              <a
                target="_blank"
                href="https://policies.google.com/technologies/partner-sites"
              >
                Google Analytics
              </a>
              , SDKs, and other third-party technologies to understand how you
              navigate through the GrowX A.i Platform and interact with
              advertisements, to make your GrowX A.i experience safer, to learn
              what content is popular, to improve your site experience, to serve
              you better ads on other sites, and to save your preferences.
              Cookies are small text files that web servers place on your
              device; they are designed to store basic information and to help
              websites and apps recognize your browser. We may use both session
              cookies and persistent cookies. A session cookie disappears after
              you close your browser. A persistent cookie remains after you
              close your browser and may be accessed every time you use the
              GrowX A.i Platform.
            </p>
            <p>
              Inferences. We may infer certain information from your
              interactions with the GrowX A.i Platform and other personal
              information available to us. We may also draw inferences from the
              information you provide to us, such as inferring your gender based
              on your first name.
            </p>
          </div>
          <div className="content-heading">
            Information We Collect From Other Sources
          </div>
          <div className="content-text">
            <p>
              Service Providers and Other Parties. Service providers and other
              parties provide us with information needed for core aspects of the
              GrowX A.i Platform, as well as for additional services, programs,
              loyalty benefits, and promotions that can enhance your GrowX A.i
              experience. These service providers and other parties include
              background check providers, insurance partners, financial service
              providers, marketing providers, and other businesses. We obtain
              the following information about you from these parties:
            </p>
            <ul>
              <li>
                Information to make the GrowX A.i Platform safer, like
                background check information or identity verification
                information to all GrowMaster/Tech;
              </li>
              <li>
                Information to operationalize loyalty and promotional/marketing
                programs or applications, services, or features you choose to
                connect or link to your GrowX A.i account, such as information
                about your use of such programs, applications, services, or
                features;
              </li>
              <li>
                Information about you provided by specific services, such as
                home or business address, demographic, and other information
                that helps us provide services or to personalize our marketing.
              </li>
            </ul>
            <p>
              Enterprise and Delivery Programs. If you use GrowX A.i through
              your employer or other organization that participates in one of
              our GrowX A.i Business enterprise programs, we will collect
              information about you from those parties, such as your name and
              contact information. If an organization has utilized GrowX A.i to
              fulfill a delivery to you, we may receive information like your
              name and delivery address from the organization in order to
              fulfill the delivery.
            </p>
            <p>
              Referral Programs. Friends help friends use the GrowX A.i
              Platform. If someone refers you to GrowX A.i, we will collect
              information about you from that referral including your name and
              contact information.
            </p>
            <p>
              Other Users and Sources. Other users or public or third-party
              sources such as law enforcement, insurers, media, or pedestrians
              may provide us information about you, for example as part of an
              investigation to provide you support.
            </p>
          </div>
          <div className="content-heading">How We Use Your Information</div>
          <div className="content-text">
            <p>We use your personal information to:</p>
            <ul>
              <li>Provide the GrowX A.i Platform;</li>
              <li>
                Maintain the security and safety of the GrowX A.i Platform and
                its users;
              </li>
              <li>Build and maintain the GrowX A.i community;</li>
              <li>Provide customer support;</li>
              <li>Improve the GrowX A.i Platform; and</li>
              <li>Respond to legal proceedings and obligations.</li>
            </ul>
            <p>
              Providing the GrowX A.i Platform. We use your personal information
              to provide an intuitive, useful, efficient, and worthwhile
              experience on our platform. To do this, we use your personal
              information to:
            </p>
            <ul>
              <li>
                Verify your identity and maintain your account, settings, and
                preferences;
              </li>
              <li>Connect you to GrowMaster/Tech and track their progress;</li>
              <li>
                Provide various GrowX A.i Platform offerings to you, such as
                garden services and third party promotional advertisements that
                may be personalized to you;
              </li>
              <li>Calculate prices and process payments;</li>
              <li>
                Allow growers and GrowMaster/Tech and other service providers to
                connect regarding their grow journey and to choose to share
                their location with others;
              </li>
              <li>
                Communicate with you about your use of the GrowX A.i Platform
                and experience;
              </li>
              <li>Collect feedback regarding your experience;</li>
              <li>
                Facilitate additional services and programs with third parties;
                and
              </li>
              <li>Operate contests, sweepstakes, and other promotions.</li>
            </ul>
            <p>
              Maintaining the Security and Safety of the GrowX A.i Platform and
              its Users. Providing you a secure and safe experience drives our
              platform, both in the garden and on our apps. To do this, we use
              your personal information to:
            </p>
            <ul>
              <li>Authenticate and verify users;</li>
              <li>
                Verify that growmasters meet background and safety requirements;
              </li>
              <li>
                Encourage safe driving behavior and avoid unsafe activities;
              </li>
              <li>Find and prevent fraud; and</li>
              <li>
                Block and remove unsafe or fraudulent users from the GrowX A.i
                Platform.
              </li>
            </ul>
            <p>
              Building and Maintaining theGrowX A.i Community. GrowX A.i works
              to be a positive part of the community. We use your personal
              information to:
            </p>
            <ul>
              <li>
                Communicate with you about events, promotions, elections, and
                campaigns;
              </li>
              <li>
                Personalize and provide content, experiences, communications,
                and targeted advertising to promote and grow the GrowX A.i
                Platform; and
              </li>
              <li>
                Help facilitate donations you choose to make through the GrowX
                A.i Platform.
              </li>
            </ul>
            <p>
              Providing Customer Support. We work hard to provide the best
              experience possible, including supporting you when you need it. To
              do this, we use your personal information to:
            </p>
            <ul>
              <li>
                Investigate and assist you in resolving questions or issues you
                have regarding theGrowX A.i Platform; and
              </li>
              <li>Provide you support or respond to you.</li>
            </ul>
            <p>
              Improving the GrowX A.i Platform. We are always working to improve
              your experience and provide you with new and helpful features. To
              do this, we use your personal information to:
            </p>
            <ul>
              <li>Perform research, testing, and analysis;</li>
              <li>
                Develop new products, features, partnerships, and services;
              </li>
              <li>
                Prevent, find, and resolve software or hardware bugs and issues;
                and
              </li>
              <li>
                Monitor and improve our operations and processes, including
                security practices, algorithms, and other modeling.
              </li>
            </ul>
            <p>
              Responding to Legal Proceedings and Requirements. Sometimes the
              law, government entities, or other regulatory bodies impose
              demands and obligations on us with respect to the services we seek
              to provide. In such a circumstance, we may use your personal
              information to respond to those demands or obligations.
            </p>
          </div>
          <div className="content-heading">
            How We Disclose Your Information
          </div>
          <div className="content-text">
            <p>
              We do not sell your personal information to third parties for
              money -- no one can buy the personal information we collect from
              and about you and we do not act as a data broker. However, we may
              need to share your personal information with third parties to
              deliver relevant personalized ads to you on and off the GrowX A.i
              Platform. Some of these disclosures may constitute “sharing” or
              the “sale” of personal information for “targeted advertising”
              purposes under certain local (e.g., U.S. state) privacy laws. This
              section otherwise explains when, why, and with whom we disclose
              your information.
            </p>
            <p>
              <b>A. Sharing Between GrowX A.i Users</b>
            </p>
            <p>Grower and GrowMaster/Tech service provider.</p>
            <p>
              “Grower” information shared with GrowMaster/Tech and other service
              providers: As part of surfacing the service request and enabling
              the service, we share with the GrowMaster/Tech the “growers''
              garden location, location, name, profile photo, rating, garden
              statistics (like approximate number of plants and years as a
              Grower), and information the Grower includes in their GrowX Ai App
              profile (like preferred name pronouns). Once the grower
              consultation is finished, we also eventually share the growers
              rating and feedback with the GrowMaster/Tech. (We remove the
              Growers identity associated with ratings and feedback when we
              share it with GrowMaster/Tech, but a GrowMaster may be able to
              identify the grower that provided the information.
            </p>
            <p>
              GrowMaster information shared with Grower: Upon a GrowMaster/Tech
              accepting a requested service, we will share with the grower the
              GrowMaster/Tech name, profile photo, preferred pronouns, rating,
              real-time location, and the vehicle make, model, color, and
              license plate, as well as other information in the GrowMaster/Tech
              profile, such as information GrowMasters choose to add (like
              country flag and why they love to grow) and GrowMaster statistics
              (like approximate number of services they experience in and years
              as a GrowMaster/Tech experience).
            </p>
            <p>
              Although we help Growers and GrowMaster/Tech communicate with one
              another to arrange a service, we do not share your actual phone
              number or other contact information with other users when enabling
              this.
            </p>
            <p>
              Linked Accounts and Sharing Upon Your Request. If you use the
              GrowX A.i Platform as part of a GrowX A.i shared account linked
              with others or if you direct us to share your information with
              trusted contacts, we will then share with those parties
              information about your use of the GrowX A.i Platform, such as the
              live location of your garden or grow facility.
            </p>
            <p>
              Disclosures to Service Providers to Provide the GrowX A.i
              Platform, and Other Parties Depending on whether you’re a “Grower
              or a “GrowMaster/Tech, GrowX A.i may disclose the following
              categories of your personal information to provide you with a
              variety of the GrowX A.i Platform’s features and services:
            </p>
            <ul>
              <li>
                Personal identifiers, such as your name, address, email address,
                phone number, date of birth, government identification number
                (such as social security number), driver’s license information,
                vehicle information.
              </li>
              <li>
                Financial information, such as bank routing numbers, tax
                information, and any other payment information you provide us;
              </li>
              <li>
                Commercial information, such as information,
                Grower/GrowMaster/Tech statistics and feedback, and transaction
                history;
              </li>
              <li>
                Internet or other electronic network activity information, such
                as your IP address, type of browser, version of operating
                system, carrier and/or manufacturer, device identifiers, and
                mobile and other forms of advertising identifiers;
              </li>
              <li>Location data; and</li>
              <li>
                This and other information you may direct us to disclose to
                other parties, such as when you choose to link your GrowX A.i
                account with a separate rewards program or when you choose to
                engage with an offering from another company through the GrowX
                A.i Platform, such as another grow service provider.
              </li>
            </ul>
            <p>
              We disclose those categories of personal information to these
              other parties and service providers to fulfill the following
              purposes:
            </p>
            <ul>
              <li>Maintaining and servicing your GrowX A.i account;</li>
              <li>
                Processing or fulfilling services or other offerings of the
                GrowX A.i Platform;
              </li>
              <li>Providing you customer service;</li>
              <li>Processing transactions and payments;</li>
              <li>Processing GrowMaster/Tech applications;</li>
              <li>Verifying the identity of users;</li>
              <li>Detecting and preventing fraud and unsafe activity;</li>
              <li>Providing loyalty and promotional programs;</li>
              <li>
                Providing marketing and advertising services to GrowX A.i ,
                including to provide targeted and cross-contextual behavioral
                advertising personalized to you;
              </li>
              <li>Providing financing;</li>
              <li>Providing requested emergency services;</li>
              <li>Providing analytics services to and by GrowX A.i; and</li>
              <li>
                Undertaking research to develop and improve the GrowX A.i
                Platform.
              </li>
            </ul>
            <p>
              <b>C. For Legal Reasons and to Protect the GrowX A.i Platform</b>
            </p>
            <p>
              We may disclose your personal information in response to a legal
              obligation, or if we have determined that disclosing your personal
              information is reasonably necessary or appropriate to:
            </p>
            <ul>
              <li>
                Comply with any applicable federal, state, or local law or
                regulation, civil, criminal or regulatory inquiry, investigation
                or legal process, enforceable governmental request, or
                requirement as condition to operate (e.g., operating permit,
                license or agreement);
              </li>
              <li>
                Respond to legal process (such as a search warrant, subpoena,
                summons, or court order);
              </li>
              <li>Enforce our Terms of Service;</li>
              <li>
                Cooperate with law enforcement agencies concerning conduct or
                activity that we reasonably and in good faith believe may
                violate federal, state, or local law; or
              </li>
              <li>
                Exercise or defend legal claims, protect against harm to our
                rights, property, interests, or safety or the rights, property,
                interests, or safety of you, third parties, or the public as
                required or permitted by law.
              </li>
            </ul>
            <p>
              <b>D. In Connection with Sale or Merger</b>
            </p>
            <p>
              We may disclose your personal information while negotiating or in
              relation to a change of corporate control such as a restructuring,
              merger, or sale of our assets.
            </p>
            <p>
              <b>E. Upon Your Further Direction</b>
            </p>
            <p>
              With your permission or upon your direction, we may disclose your
              personal information to interact with a third party or for other
              purposes.
            </p>
          </div>
          <div className="content-heading">
            How We Store and Protect Your Information
          </div>
          <div className="content-text">
            <p>
              We retain your information for as long as necessary to provide you
              and our other users the GrowX A.i Platform. This means we keep
              your profile information for as long as you maintain an account.
              We retain transactional information such as grow projects and
              payments for at least seven years to ensure we can perform
              legitimate business functions, such as accounting for tax
              obligations. We also retain your information as necessary to
              comply with our legal obligations, resolve disputes and enforce
              our terms and policies. If you request account deletion, we will
              delete your information as set forth in the “Deleting Your
              Account” section below.
            </p>
            <p>
              We take reasonable and appropriate measures designed to protect
              your personal information. But no security measures can be 100%
              effective, and we cannot guarantee the security of your
              information, including against unauthorized intrusions or acts by
              third parties.
            </p>
          </div>
          <div className="content-heading">
            Your Rights And Choices Regarding Your Data
          </div>
          <div className="content-text">
            <p>
              As explained more below and in this policy and other terms and
              conditions, GrowX A.i provides ways for you to access and delete
              your personal information as well as exercise applicable data
              rights that give you certain control over your personal
              information.
            </p>
            <p>
              <b>A. All Users</b>
            </p>
            <p>
              Email Subscriptions. You can always unsubscribe from our
              commercial or promotional emails by clicking unsubscribe in those
              messages. We will still send you transactional and relational
              emails about your use of the GrowX A.i Platform.
            </p>
            <p>
              Text Messages. You can opt out of receiving commercial or
              promotional text messages by texting from the mobile device
              receiving the messages. You may also opt out of receiving all
              texts from GrowX A.i (including transactional or relational
              messages) by texting the word STOP from the mobile device
              receiving the messages. Note that opting out of receiving all
              texts may impact your use of the GrowX A.i Platform.
            </p>
            <p>
              Push Notifications. You can opt out of receiving push
              notifications through your device settings. Please note that
              opting out of receiving push notifications may impact your use of
              the GrowX A.i Platform (such as receiving a notification that your
              service provider has arrived).
            </p>
            <p>
              Profile Information. You can review and edit certain account
              information you have chosen to add to your profile by logging in
              to your account settings and profile.
            </p>
            <p>
              Location Information. You can prevent your device from sharing
              location information through your device’s system settings. But if
              you do, this may impact the ability to provide you our full range
              of features and services. You may also control some elements of
              sharing your location with GrowX A.i and our GrowX A.i account
              settings.
            </p>
            <p>
              Cookie Tracking. You can modify your cookie settings on your
              browser. You may also further control your cookie setting as you
              wish.
            </p>
            <p>
              Accessing Your Information. If you would like to access your
              personal information, please visit your account at www.growxai.io
              . You can also see information we have about you by logging into
              your account and viewing things like your profile, settings, and
              preferences, service history, or payment information. In addition,
              you may have some information included in things we have sent to
              you, such as subscription and or service receipts.
            </p>
            <p>
              Deleting Your Account. If you would like to delete your GrowX A.i
              account, please visit our website at https://growxai.io In some
              cases, we will be unable to delete your account, such as if there
              is an issue with your account related to trust, safety, or fraud.
              When we delete your account, we may retain certain information for
              legitimate business purposes or to comply with legal or regulatory
              obligations.
            </p>
            <p>
              <b>B. Location-Specific Rights</b>
            </p>
            <p>
              Some states such as California, Colorado, Connecticut, Montana,
              Oregon, Texas, Utah, and Virginia provide specific rights to
              residents of the state regarding personal information. Similarly,
              specific rights may be provided in Canada.
            </p>
          </div>
          <div className="content-heading">Children’s Data</div>
          <div className="content-text">
            <p>
              GrowX A.i is not directed to children, and we don’t knowingly
              collect personal information from children under the age of 18. If
              we find out that a child under 18 has given us personal
              information, we will take steps to delete that information. If you
              believe that a child under the age of 18 has given us personal
              information, please contact us at https://growxai.io/
            </p>
          </div>
          <div className="content-heading">Links to Third-Party Websites</div>
          <div className="content-text">
            <p>
              The GrowX A.i Platform may contain links or references to
              third-party websites, products, or services. Those third parties
              may have privacy policies that differ from ours. We are not
              responsible for those third parties and their websites, products
              or services, and we recommend that you review their policies.
              Please contact those parties directly if you have any questions
              about their privacy policies.
            </p>
          </div>
          <div className="content-heading">Changes to This Privacy Policy</div>
          <div className="content-text">
            <p>
              We may update this policy from time to time as the GrowX A.i
              Platform changes and privacy law evolves. If we update it, we will
              do so online, and if we make material changes, we will let you
              know through the GrowX A.i Platform or by some other method of
              communication like email. When you use GrowX A.i, you are agreeing
              to the most recent terms of this policy.
            </p>
          </div>
          <div className="content-heading">Contact Us</div>
          <div className="content-text">
            <p>
              If you have any questions or concerns about your privacy or
              anything in this policy, including if you need to access this
              policy in an alternative format, we encourage you to contact us
              at;{" "}
              <a target="_blank" href="https://growxai.io/">
                https://growxai.io/
              </a>
            </p>
          </div>
          <div className="content-heading">Account Deletion</div>
          <div className="content-text">
            <p>
              Click on the link to delete your account:{" "}
              <Link to={"/account-deletion"}>Link</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
