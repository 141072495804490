import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./ScrollToTop";
import Footer from "./components/footer/Footer";
import AccountDeletion from "./pages/AccountDeletion/AccountDeletion";
import Contact from "./pages/Contact/Contact";
import About from "./pages/about/About";
// import Countdown from "./pages/countdown/Countdown";
import Header from "./pages/header/Header";
import Home from "./pages/home/Home";
import Login from "./pages/login/login";
import Pricing from "./pages/pricing/Pricing";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import StartGrowing from "./pages/start-growing/start-growing";
import TermsConditionsPage from "./pages/terms-conditions/TermsConditions";
import Tutorial from "./pages/tutorial/tutorial";
import Faq from "./pages/faq/faq";
import SignUp from "./pages/SignUp/SignUp";
import VerifyEmail from "./pages/verifyEmail/verifyEmail";
import Profile from "./pages/Profile/Profile";
import ForgetPassword from "./pages/forgetPassword";
import PaypalPayment from "./pages/PayPalSuccess";
import Notifications from "./pages/notifications";
import { NotificationsProvider } from "./utils/NotificationsContext";
import Projects from "./pages/projects";
// import NewHeader from "./pages/NewHeader/NewHeader";
const MEASUREMENT_ID = "G-MK01BC46RX"; // MEASUREMENT_ID
ReactGA.initialize(MEASUREMENT_ID);

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <NotificationsProvider>
      <div className="App">
        <header className="sticky-header">
          <Header />
        </header>
        <Routes>
          {/* <Route path="/" element={<Countdown />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/start-growing" element={<StartGrowing />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/about" element={<About />} />
          <Route path="/tutorials" element={<Tutorial />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditionsPage />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/account-deletion" element={<AccountDeletion />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/paypal-payment" element={<PaypalPayment />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/projects" element={<Projects />} />
        </Routes>
        <Footer />
      </div>
      </NotificationsProvider>
    </BrowserRouter>
  );
}

export default App;
