import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Profile from "../Profile/Profile";
import RegisterForm from "../register/RegisterForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../login/login.css";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";

const ForgetPassword = () => {
  const [showRegisterModal, setRegisterModalShow] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [emailOtpPForm, setEmailOtpPForm] = useState(true);
  const [newPassForm, setNewPassForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };

  const handleClose = () => {
    setRegisterModalShow(false);
  };
  const handleShow = () => {
    navigate("/register");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    const payload = {
      email,
      app: "GROWX",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/send-reset-link`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        setEmailOtpPForm(false);
        setNewPassForm(true);
      } else {
        throw new Error(
          data.message || "Login failed. Please check your credentials."
        );
      }
    } catch (error) {
      setError(error.message);
      toast.error(error.message);
    }
  };

  const handleSubmitNewPassword = async (event) => {
    event.preventDefault();
    const otpCode = otp.join("");
    if (otpCode.length < 6) {
      setErrorMessage("Please enter a 6-digit OTP code.");
      return;
    }

    if (otp.some((digit) => digit === "")) {
      setErrorMessage("Please fill in all OTP fields.");
      return;
    }

    setError("");
    const payload = {
      email,
      password,
      token: otpCode,
      app: "GROWX",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        throw new Error("OTP validation failed. Please try again.");
      }
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        throw new Error(
          data.message || "Failed to reset password. Please try again."
        );
      }
    } catch (error) {
      setError(error.message);
      toast.error(error.message);
    }
  };

  return (
    <div className="auth-wrapper">
      <ToastContainer position="top-right" autoClose={3000} />
      <div>
        <div className="login-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xl-6 m-auto align-self-center">
                <div className="login-form">
                  <div className="login-form-wrapper">
                    <div className="text-center">
                      <img
                        src="/favicon.ico"
                        width={120}
                        className="img-fluid mb-4"
                        alt="icon"
                      />
                    </div>
                    <div className="welcome-back">
                      <h1>Forget Password</h1>
                    </div>
                    <div className="get-started">
                      {emailOtpPForm && (
                        <h3>
                          If you've forgotten your password, please enter your
                          email address below to receive a link to reset it
                        </h3>
                      )}
                      {newPassForm && (
                        <h3>Enter your OTP and add a new password</h3>
                      )}
                    </div>
                    {emailOtpPForm && (
                      <form
                        className="pb-3 submit-email-form"
                        onSubmit={handleSubmit}
                      >
                        <div className="form-group mb-2">
                          <label>Email Address</label>
                          <input
                            type="email"
                            placeholder="Enter your Email here"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                        {error && (
                          <div className="text-danger text-center">{error}</div>
                        )}{" "}
                        <div className="text-center pt-4">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm py-2 px-4"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}

                    {newPassForm && (
                      <form
                        className="pb-3 submit-pass-form"
                        onSubmit={handleSubmitNewPassword}
                      >
                        <div className="otp-section">
                          <div className="otp-inputs d-flex justify-content-center gap-2 py-4">
                            {otp.map((digit, index) => (
                              <input
                                key={index}
                                type="text"
                                maxLength="1"
                                className="otp-input text-center fs-5"
                                value={digit}
                                onChange={(e) => handleChange(e.target, index)}
                                onKeyDown={(e) => handleBackspace(e, index)}
                                required
                              />
                            ))}
                          </div>
                          {errorMessage && (
                            <p className="text-danger text-center">
                              {errorMessage}
                            </p>
                          )}
                        </div>
                        <div className="form-group position-relative">
                          <label>Password</label>
                          <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your new password here"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                           <span
                          onClick={togglePasswordVisibility}
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "65%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: '#00898d',
                          }}
                        >
                          {showPassword ? <FaEyeSlash className="fs-5"/> : <FaEye className="fs-5"/>}
                        </span>
                        </div>
                        {error && (
                          <div className="text-danger text-center">{error}</div>
                        )}
                        <div className="text-center pt-4">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm py-2 px-4"
                          >
                            Add New Password
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showProfile && <Profile />}
        <Modal show={showRegisterModal} onHide={handleClose} size="xl" centered>
          <Modal.Body
            className="register-modal"
            style={{
              backgroundImage: `url("./images/bg-logo.svg")`,
            }}
          >
            <div className="close-modal" onClick={handleClose}>
              <img src="./images/close.svg" className="" alt="close" />
            </div>
            <RegisterForm
              closeRegisterModal={() => setRegisterModalShow(false)}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default ForgetPassword;
