import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import GrowLogIcon from "../../assets/images/grow-log-icon.png";
import "../../assets/style/index.css";
import Select from 'react-select';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import Plant1 from "../../assets/images/optionslider-plant.png";
import LectureLink from "../../assets/images/Video+Overlay1.png"
import '../../assets/style/radio.css'
import axios from "axios";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const WeekSelectOption = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },


]

const DaysSelectOption = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
]

const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        color: 'white',
        textAlign: 'center',

    }),
};


const GrowDaily = () => {
    const [plantData, setPlantData] = useState(false);
    const ProceedNext = () => {
        setPlantData(true);
    }

    return (

        <>
            <Col className="after-submit mb-4">
                <div className="grow-log-options-sec bg-white rounded-3 py-5 px-2 px-lg-5 d-flex flex-wrap justify-content-between">
                    <div className="select-plant col-12 col-lg-5">
                        <h3 className="text-center mb-3">Select Plant</h3>
                        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                            <SwiperSlide>
                                <img className="w-100 h-100" src={Plant1} alt="plant" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className="w-100 h-100" src={Plant1} alt="plant" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className="w-100 h-100" src={Plant1} alt="plant" />
                            </SwiperSlide>
                        </Swiper>
                    </div>

                    <div className="col-12 col-lg-6 d-flex flex-wrap mt-md-5 mt-lg-0">
                        <div className="select-week col-12 col-md-6 pe-md-3 mb-4 mb-lg-0">
                            <h3 className="text-center mb-3">Select Week</h3>
                            <div className="option-box p-5">
                                <p className="text-white text-center fs-2 mb-0">Week</p>
                                <Select options={WeekSelectOption}
                                    placeholder="Select a week"
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                        <div className="select-days col-12 col-md-6 ps-md-3 mb-4 mb-lg-0">
                            <h3 className="text-center mb-3">Select Day</h3>
                            <div className="option-box p-5">
                                <p className="text-white text-center fs-2 mb-0">Day</p>
                                <Select options={DaysSelectOption}
                                    placeholder="Select a Day"
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-5">
                    {!plantData && (
                        <button onClick={ProceedNext} className="py-2 px-4 proceed-btn">Proceed Next</button>
                    )}
                </div>
                {plantData && (
                    <div className="plant-data-sec container">
                        <h3 className="text-white text-center fs-1 fw-bolder">Plant Data</h3>
                        <div className="table-responsive">
                            <table class="table text-white mt-5">
                                <thead>
                                    <tr>
                                        <th scope="col" className="bg-transparent border-0 text-white fs-3 w-100">Environment</th>
                                        <th scope="col" className="bg-transparent border-0 text-white fs-3 fw-500 text-center">High</th>
                                        <th scope="col" className="bg-transparent border-0 text-white fs-3 fw-500 text-center">Low</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className="bg-transparent border-0 text-white fs-5 fw-500">Temperature</th>
                                        <td className="bg-transparent border-0 text-white">
                                            <input type="number" />
                                        </td>
                                        <td className="bg-transparent border-0 text-white">
                                            <input type="number" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-transparent border-0 text-white fs-5 fw-500">Humidity %</th>
                                        <td className="bg-transparent border-0 text-white">
                                            <input type="number" />
                                        </td>
                                        <td className="bg-transparent border-0 text-white">
                                            <input type="number" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-transparent border-0 text-white fs-5 fw-500">CO2 PPM</th>
                                        <td className="bg-transparent border-0 text-white">
                                            <input type="number" />
                                        </td>
                                        <td className="bg-transparent border-0 text-white">
                                            <input type="number" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive">
                            <table class="table text-white mt-4">
                                <thead>
                                    <tr>
                                        <th scope="col" className="bg-transparent border-0 text-white fs-3 w-100">Feeding</th>
                                        <th scope="col" className="bg-transparent border-0 text-white fs-3 fw-500 text-center">High</th>
                                        <th scope="col" className="bg-transparent border-0 text-white fs-3 fw-500 text-center">Low</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className="bg-transparent border-0 text-white fs-5 fw-500">Water  volume</th>
                                        <td className="bg-transparent border-0 text-white">
                                            <input type="number" />
                                        </td>
                                        <td className="bg-transparent border-0 text-white">
                                            <input type="number" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-transparent border-0 text-white fs-5 fw-500">Water pH</th>
                                        <td className="bg-transparent border-0 text-white">
                                            <input type="number" />
                                        </td>
                                        <td className="bg-transparent border-0 text-white">
                                            <input type="number" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-transparent border-0 text-white fs-5 fw-500">Water Temp</th>
                                        <td className="bg-transparent border-0 text-white">
                                            <input type="number" />
                                        </td>
                                        <td className="bg-transparent border-0 text-white">
                                            <input type="number" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="doc-sec d-flex justify-content-between flex-wrap mt-4">
                            <div className="col-12 col-lg-6 mb-5 mb-lg-0">
                                <img className="w-100 h-auto" src={LectureLink} alt="lecture" />
                            </div>
                            <div className="col-12 col-lg-3 d-flex flex-column gap-4 justify-content-center align-item-center">
                                <button className="doc-upload-btn py-2">
                                    Upload Picture
                                    <span className="ms-3">
                                        <svg width="25" height="30" viewBox="0 0 45 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22.4983 37.7375V21.6553M22.4983 21.6553L28.7525 27.9095M22.4983 21.6553L16.2441 27.9095" stroke="#00898D" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M36.7911 29.8888C39.4602 28.8431 42.1518 26.4608 42.1518 21.656C42.1518 14.5084 36.1955 12.7215 33.2173 12.7215C33.2173 9.14764 33.2173 2 22.4958 2C11.7744 2 11.7744 9.14764 11.7744 12.7215C8.7962 12.7215 2.83984 14.5084 2.83984 21.656C2.83984 26.4608 5.53143 28.8431 8.20057 29.8888" stroke="#00898D" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                </button>
                                <button className="py-2 save-btn">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </Col>
        </>

    )
}

export default GrowDaily;
