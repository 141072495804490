import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "600px",
};

function MapPage({ users }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyALtPY2K3RllnOU4sMd_iTH6cLsKX1mEyA",
  });
  const [map, setMap] = React.useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [center, setCenter] = useState({
    lat: 34.0367,
    lng: -118.2748,
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          }
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const onLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    users.forEach(({ location }) =>
      bounds.extend({ lat: location.latitude, lng: location.longitude })
    );
    map.fitBounds(bounds);
    setMap(map);
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={8}
      onUnmount={onUnmount}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
      }}
      onLoad={onLoad}
      onClick={() => setActiveMarker(null)}
    >
      <>
        {users.map(({ id, photo, type, username, email, location }) => (
          <Marker
            key={id}
            position={{
              lat: location.latitude,
              lng: location.longitude,
            }}
            onClick={() => handleActiveMarker(id)}
          >
            {activeMarker === id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div className="info-window">
                  {photo ? (
                    <div className="photo">
                      <img src={photo} alt={username} />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="type pt-1 pb-1">{type}</div>
                  <div className="username pb-1">{username}</div>
                  <div className="email">{email}</div>
                </div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
      </>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MapPage);
