import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./verify.css";

const VerifyEmail = (props) => {
  const location = useLocation();
  const email = location.state?.email;
  const { closeRegisterModal, theme } = props;
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };

  const handleSubmit = async () => {

    const otpCode = otp.join("");
    if (otpCode.length < 6) {
      setErrorMessage("Please enter a 6-digit OTP code.");
      return;
    }

    const payload = {
      email: email,
      token: otpCode,
      app: "GROWX",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/complete-sign-up`,
        payload
      );
      if (response.status === 200) {
        localStorage.setItem("grower_access_token", response.data.token);
        toast.success("Signup completed successfully!");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        throw new Error(
          response.daat.message ||
            "Login failed. Please check your credentials."
        );
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const errorMessage =
          error.response.data.message || "An error occurred. Please try again.";
        setError(errorMessage);
        toast.error(errorMessage);
      } else {
        setError(error.message);
        toast.error(error.message);
      }
    }
  };

  const handleResendToken = async () => {
    const payload = {
      email: email,
      app: "GROWX",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/get-token`,
        payload
      );
      if (response.status === 200 && response.data.status === true) {
        toast.success(
          response.data.message || "OTP has been resent successfully!"
        );
      } else {
        toast.error(response.data.message || "Failed to resend OTP.");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      toast.error("Failed to resend OTP. Please try again later.");
    }
  };

  return (
    <>
     
    <div className={theme}>
    <ToastContainer/>
      <div className="register-form registration-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-10 col-xl-10 m-auto align-self-center">
              <div className="registration-form-wrapper">
                <div className="text-center">
                  <img
                    src="/favicon.ico"
                    width="120"
                    className="img-fluid mb-4"
                    alt="Logo"
                  />
                </div>
                <div className="section-heading">
                  <h1 className="text-uppercase">Verify Your Email</h1>
                  <p className="text-black text-center">
                    We have sent you an email with a verification code. Please
                    verify it.
                  </p>
                  <div className="otp-section">
                    <div className="otp-inputs d-flex justify-content-center gap-2 py-4">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="text"
                          maxLength="1"
                          className="otp-input text-center fs-5"
                          value={digit}
                          onChange={(e) => handleChange(e.target, index)}
                          onKeyDown={(e) => handleBackspace(e, index)}
                        />
                      ))}
                    </div>
                    {errorMessage && (
                      <p className="text-danger text-center">{errorMessage}</p>
                    )}
                    {successMessage && (
                      <p className="text-success text-center">
                        {successMessage}
                      </p>
                    )}
                    <div className="d-flex justify-content-center align-items-center mt-3">
                      <button
                        className="btn btn-primary px-5"
                        onClick={handleSubmit}
                      >
                        Verify
                      </button>
                    </div>

                    <div className="d-flex justify-content-center align-items-center pt-4 gap-1">
                      <p className="text-black mb-0">
                        If you didn't receive the code, click here
                      </p>
                      <button
                        onClick={handleResendToken}
                        className="border-0 bg-transparent resend-otp-btn"
                      >
                        Resend Token
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default VerifyEmail;
