import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HgpTermsConditionsContent from "../HgpTermsConditionsContent/HgpTermsConditionsContent";
import LearnMoreContent from "../LearnMoreContent/LearnMoreContent";
import TermsConditionsContent from "../TermsConditionsContent/TermsConditionsContent";
import RegisterForm from "../register/RegisterForm";
import { IoMdNotifications } from "react-icons/io";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./header.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import Loader from "../../components/loader";
import { useNotifications } from "../../utils/NotificationsContext";

function Header() {
  const { unreadCount } = useNotifications();
  const { unreadNotifications, markNotificationAsRead } = useNotifications();
  const { notifications } = useNotifications();
  const [showRegisterModal, setRegisterModalShow] = useState(false);
  const [showLearnMoreModal, setLearnMoreModalShow] = useState(false);
  const [showTermsModal, setTermModalShow] = useState(false);
  const [showHgpTermsModal, setHgpTermModalShow] = useState(false);
  const [goBackRoute, setGoBackRoute] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("grower_user");
    setIsLoggedIn(!!user);
  }, [location]);

  useEffect(() => {
    if (showRegisterModal) {
      handleClose();
    }
  }, [location]);

  const handleClose = () => {
    setGoBackRoute("");
    setRegisterModalShow(false);
  };
  const handleShow = () => setRegisterModalShow(true);

  const handleLearnMoreClose = () => {
    setGoBackRoute("");
    setLearnMoreModalShow(false);
  };
  const handleLearnMoreShow = () => setLearnMoreModalShow(true);

  const handleTermsClose = () => {
    setGoBackRoute("");
    setTermModalShow(false);
  };
  const handleTermsShow = () => setTermModalShow(true);

  const handleHgpTermsClose = () => {
    setGoBackRoute("");
    setHgpTermModalShow(false);
  };
  const handleHgpTermsShow = () => setHgpTermModalShow(true);

  const openRegisterModal = () => {
    handleLearnMoreClose();
    handleHgpTermsClose();
    handleTermsClose();
    handleShow();
  };

  const openTermConditionModal = () => {
    handleLearnMoreClose();
    handleTermsShow();
  };

  const openHgpTermConditionModal = () => {
    handleLearnMoreClose();
    handleHgpTermsShow();
  };

  const signOutNow = () => {
    localStorage.removeItem("grower_user");
    localStorage.removeItem("GrowFormAdded");
    setIsLoggedIn(false);
  };

  const handleNavItemClick = () => {
    setExpanded(false);
  };

  return (
    <>
      <Navbar expanded={expanded} expand="lg" className="navbar-dark">
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img
                src="/logos/growxAI-color.svg"
                width="160"
                height="54"
                className="d-inline-block align-top"
                alt="GrowX"
              />
            </Link>
          </Navbar.Brand>
          <div className="d-flex gap-1 gap-md-3">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setExpanded(!expanded)}
            />
             <div className="d-block d-lg-none">
            <Dropdown autoClose="inside" className="header-notification-link">
              <Dropdown.Toggle className="toggle bg-transparent position-relative">
                <IoMdNotifications className="fs-3 noti-bill" />

                {notifications.filter(
                  (notification) => notification.status === "0"
                ).length > 0 && (
                  <span className="notification-badge">
                    {unreadNotifications.length}
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="px-3 py-4 right-0 notification-model-main">
                <div className="d-flex justify-content-between">
                  <p className="notification-count">
                    Notifications ( {unreadNotifications.length}
                    unread)
                  </p>
                </div>
                {notifications.length === 0 ? (
                  <div className="loader-wrapper py-5 my-5 mx-auto w-100 d-flex justify-content-center align-items-center">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {unreadNotifications
                      .filter((notification) => notification.status === "0")
                      .slice(0, 7)
                      .map((notification, index) => (
                        <div
                          key={notification.id}
                          className="py-2 px-4 rounded-2 mb-1"
                          onClick={() =>
                            markNotificationAsRead(notification.id)
                          }
                          style={{
                            backgroundColor:
                              notification.status === "1"
                                ? "#ffffff"
                                : "#f0f0f0",
                            cursor: "pointer",
                          }}
                        >
                          <div className="header-notification-content">
                            <p className="mb-0 fw-bold">
                              {notification.title || "New Notification"}
                            </p>
                            <p className="mb-0">{notification.content}</p>
                          </div>
                        </div>
                      ))}
                    {notifications.filter(
                      (notification) => notification.status === "0"
                    ).length > 0 && (
                      <Dropdown.Item>
                        <Nav.Link
                          as={Link}
                          to="/notifications"
                          className="view-all-notifications mt-4"
                        >
                          View All Notifications
                        </Nav.Link>
                      </Dropdown.Item>
                    )}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
            </div>
          </div>
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link
                as={Link}
                className={location.pathname === "/" ? "active-nav" : ""}
                to="/"
                onClick={handleNavItemClick}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                className={
                  location.pathname === "/start-growing" ? "active-nav" : ""
                }
                to="/start-growing"
                onClick={handleNavItemClick}
              >
                Start Growing
              </Nav.Link>
              <Nav.Link
                as={Link}
                className={location.pathname === "/about" ? "active-nav" : ""}
                to="/about"
                onClick={handleNavItemClick}
              >
                About
              </Nav.Link>
              <Nav.Link
                as={Link}
                className={
                  location.pathname === "/tutorials" ? "active-nav" : ""
                }
                to="/tutorials"
                onClick={handleNavItemClick}
              >
                Tutorial
              </Nav.Link>
              <Nav.Link
                as={Link}
                className={location.pathname === "/pricing" ? "active-nav" : ""}
                to="/pricing"
                onClick={handleNavItemClick}
              >
                Pricing
              </Nav.Link>
              {!isLoggedIn ? (
                <Nav.Link onClick={handleShow}>Registration</Nav.Link>
              ) : (
                ""
              )}
            </Nav>

            {!isLoggedIn ? (
              <Nav.Link
                className="ms-lg-1 ms-0 mt-lg-0 mt-2"
                to="/login"
                as={Link}
              >
                <img
                  src="/images/avatar.png"
                  className="img-fluid pointer"
                  width={30}
                  alt="LOGGED"
                />
              </Nav.Link>
            ) : (
              <>
                {isLoggedIn && (
                  <Dropdown autoClose="inside">
                    <Dropdown.Toggle
                      className="toggle"
                      id="dropdown-autoclose-inside"
                    >
                      <Nav.Link className="ms-lg-1 ms-0 mt-lg-0 mt-2">
                        <img
                          src="/images/avatar.png"
                          className="img-fluid pointer"
                          width={30}
                          alt="LOGGED"
                        />
                      </Nav.Link>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {isLoggedIn ? (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              history("/profile");
                            }}
                          >
                            Profile
                          </Dropdown.Item>

                          <Dropdown.Item onClick={signOutNow}>
                            Sign Out
                          </Dropdown.Item>
                        </>
                      ) : (
                        <div>
                          <Dropdown.Item
                            onClick={() => {
                              history("/login");
                            }}
                          >
                            Sign In
                          </Dropdown.Item>
                        </div>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </>
            )}
          </Navbar.Collapse>
          <div className="d-none d-lg-block">
            <Dropdown autoClose="inside" className="header-notification-link">
              <Dropdown.Toggle className="toggle bg-transparent position-relative">
                <IoMdNotifications className="fs-3 noti-bill" />

                {notifications.filter(
                  (notification) => notification.status === "0"
                ).length > 0 && (
                  <span className="notification-badge">
                    {unreadNotifications.length}
                  </span>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu className="px-3 py-4 right-0 notification-model-main ">
                <div className="d-flex justify-content-between">
                  <p className="notification-count">
                    Notifications ( {unreadNotifications.length}
                    unread)
                  </p>
                </div>
                {notifications.length === 0 ? (
                  <div className="loader-wrapper py-5 my-5 mx-auto w-100 d-flex justify-content-center align-items-center">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {unreadNotifications
                      .filter((notification) => notification.status === "0")
                      .slice(0, 7)
                      .map((notification, index) => (
                        <div
                          key={notification.id}
                          className="py-2 px-4 rounded-2 mb-1"
                          onClick={() =>
                            markNotificationAsRead(notification.id)
                          }
                          style={{
                            backgroundColor:
                              notification.status === "1"
                                ? "#ffffff"
                                : "#f0f0f0",
                            cursor: "pointer",
                          }}
                        >
                          <div className="header-notification-content">
                            <p className="mb-0 fw-bold">
                              {notification.title || "New Notification"}
                            </p>
                            <p className="mb-0">{notification.content}</p>
                          </div>
                        </div>
                      ))}
                    {notifications.filter(
                      (notification) => notification.status === "0"
                    ).length > 0 && (
                      <Dropdown.Item>
                        <Nav.Link
                          as={Link}
                          to="/notifications"
                          className="view-all-notifications mt-4"
                        >
                          View All Notifications
                        </Nav.Link>
                      </Dropdown.Item>
                    )}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
      </Navbar>
      {/* Modal Components */}
      <Modal show={showRegisterModal} onHide={handleClose} size="xl" centered>
        <Modal.Body
          className="register-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleClose();
                handleLearnMoreShow();
                setGoBackRoute("");
              }}
            >
              <img src="./images/go-back.svg" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleClose}>
            <img src="./images/close.svg" alt="close" />
          </div>
          <RegisterForm handleClose={handleClose} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showLearnMoreModal}
        onHide={handleLearnMoreClose}
        size="xl"
        centered
      >
        <Modal.Body
          className="learn-more-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          <div className="close-modal" onClick={handleLearnMoreClose}>
            <img src="./images/close.svg" alt="close" />
          </div>
          <LearnMoreContent
            openRegisterModal={() => {
              openRegisterModal();
              setGoBackRoute("true");
            }}
            openTermConditionModal={() => {
              openTermConditionModal();
              setGoBackRoute("true");
            }}
            openHgpTermConditionModal={() => {
              openHgpTermConditionModal();
              setGoBackRoute("true");
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showTermsModal} onHide={handleTermsClose} size="xl" centered>
        <Modal.Body
          className="terms-condition-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleTermsClose();
                handleLearnMoreShow();
                setGoBackRoute("");
              }}
            >
              <img src="./images/go-back.svg" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleTermsClose}>
            <img src="./images/close.svg" alt="close" />
          </div>
          <TermsConditionsContent
            openRegisterModal={() => {
              openRegisterModal();
              setGoBackRoute("true");
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showHgpTermsModal}
        onHide={handleHgpTermsClose}
        size="xl"
        centered
      >
        <Modal.Body
          className="terms-condition-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleHgpTermsClose();
                handleLearnMoreShow();
              }}
            >
              <img src="./images/go-back.svg" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleHgpTermsClose}>
            <img src="./images/close.svg" alt="close" />
          </div>
          <HgpTermsConditionsContent
            openRegisterModal={() => {
              openRegisterModal();
              setGoBackRoute("true");
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
