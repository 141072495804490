import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './notification.css';
import { MdDeleteForever } from "react-icons/md";
import Loader from "../../components/loader";
import { useNotifications } from "../../utils/NotificationsContext";

const Notifications = () => {
    const { notificationsUpdate, updateNotifications } = useNotifications();
    const { notifications, markNotificationAsRead, deleteNotification } = useNotifications();
    const sortedNotifications = notifications.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    return (
        <>
            <div className='notification-main '>
                <ToastContainer />
                <div className="hero d-flex justify-content-center align-items-center">
                    <div className="container">
                        <div className="hero-content d-flex flex-column justify-content-center align-items-center">
                            <h1 className="from-seeds-to text-center">
                                <span className="mx-3">Grower Notifications</span>
                            </h1>
                        </div>
                    </div>
                </div>

                <div className="container notification-sec py-5">
                    {notifications.length === 0 ? (
                        <div className="loader-wrapper py-5 my-5 mx-auto w-100 d-flex justify-content-center align-items-center">
                            <Loader />
                        </div>
                    ) : (
                        <div className="notifications bg-white rounded-3 p-2 p-lg-5">
                            {notifications.map((notification) => (
                                <div className="position-relative">
                                    <button onClick={() => deleteNotification(notification.id)} type="button" className="border-0 n-del-btn p-1 position-absolute">
                                        <MdDeleteForever />
                                    </button>
                                    <div
                                        key={notification.id}
                                        className={`single-notification ms-4 ms-md-5 mb-3 px-4 py-2 shadow-md rounded-3 d-flex flex-column flex-md-row justify-content-between gap-2 gap-lg-5`}
                                        onClick={() => markNotificationAsRead(notification.id)}
                                        style={{
                                            backgroundColor: notification.status === "1" ? "#ffffff" : " #f0f0f0",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div className="d-flex gap-2 gap-lg-5">

                                            <div className="noti-body">
                                                <p className="n-type mb-0 text-uppercase">{notification.type}</p>
                                                <p className="mb-0 n-title">{notification.title}</p>
                                                <p className="mb-0 n-descr">{notification.content}</p>
                                            </div>
                                        </div>
                                        <div className="noti-head">
                                            <p className="mb-0 n-date">{notification.created_at}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Notifications;
